import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import styled from '@emotion/styled'
import { mq } from '@totmoney/grid'
import Grid from 'components/Grid'
import { H4, RichText } from 'components/Typography'
import Stack from './Stack'
import { Document } from '@contentful/rich-text-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, IconDefinition } from '@fortawesome/free-regular-svg-icons'
import { faCube, faLocationDot, faRepeat } from '@fortawesome/free-solid-svg-icons'
import { lightColors } from '@totmoney/colors'
import { LinkInfo } from './LinkComponent'
import { LinkTrackEvent } from 'helpers/events/components/LinkTrackEvent'

const iconMappings: Record<string, IconDefinition> = {
  pin: faLocationDot,
  check: faCircleCheck,
  repeat: faRepeat,
  cube: faCube
}

const SingleCard = styled(Stack)({
  gridColumnEnd: 'span 4',
  alignItems: 'flex-start',
  padding: '0',

  [mq.xs]: {
    gap: '1.5rem',
    gridColumnEnd: 'span 3',
    padding: '0 2rem 0 0'
  }
})

const QuadCard = ({
  elements
}: {
  elements: {
    icon: string
    title: string
    text?: string
    description?: { json: Document }
    link?: LinkInfo
  }[]
}) => {
  return (
    <>
      <Grid css={{ rowGap: '2rem' }}>
        {elements.map((el, i) => {
          // Handle legacy text
          const copy = el.description ? documentToReactComponents(el.description.json) : el.text
          return (
            <SingleCard key={i}>
              {iconMappings[el.icon] ? (
                <FontAwesomeIcon
                  css={{
                    width: '25px',
                    height: '25px',
                    color: `var(--accent, ${lightColors.accent.base})`
                  }}
                  icon={iconMappings[el.icon]}
                />
              ) : null}
              <H4 as='h3' css>
                {el.title}
              </H4>
              <RichText>{copy}</RichText>
              {el.link ? (
                <LinkTrackEvent {...el.link} href={el.link.url} type='linkWithIcon' icon='link' adaptable />
              ) : null}
            </SingleCard>
          )
        })}
      </Grid>
    </>
  )
}

export default QuadCard
