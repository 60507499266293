import styled from '@emotion/styled'

import { mq } from '@totmoney/grid'
import Image from 'next/image'

import Forbes from 'public/images/Forbes.svg'
import MilanoFinanza from 'public/images/Milano_finanza.svg'
import Repubblica from 'public/images/La_Repubblica.svg'
import Sole24ore from 'public/images/Il_Sole_24_Ore.svg'

const DEFAULT_LOGOS = [
  { logo: Sole24ore, alt: 'logo il sole 24 ore' },
  { logo: MilanoFinanza, alt: 'logo milano finanza' },
  { logo: Forbes, alt: 'logo forbes' },
  { logo: Repubblica, alt: 'logo la repubblica' },
]

const Slider = styled.div({
  display: 'flex',
  alignItems: 'center',
  overflowX: 'scroll',
  gap: '1.5rem',
  paddingBlock: '2rem', //used to increse the clickable area in mobile scroll
  paddingInline: '1.5rem',
  scrollbarWidth: 'none',

  '&::-webkit-scrollbar': {
    display: 'none',
  },

  [mq.xxs]: {
    justifyContent: 'center',
  },
})

const LogoSlider = ({ logos = DEFAULT_LOGOS }: { logos: { logo: any; alt: string }[] }) => {
  return (
    <Slider>
      {logos.map((el) => (
        <Image key={el.logo} src={el.logo} alt={el.alt} height={24}></Image>
      ))}
    </Slider>
  )
}

export default LogoSlider
