import { css } from '@emotion/react'
import { lightColors } from '@totmoney/colors'
import emotionNormalize from 'emotion-normalize'

// Based on '@totmoney/globalstyle' but with less clutter
const siteStyles = css`
  ${emotionNormalize}

  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }

  html,
  body {
    margin: 0;
    font-family: 'Inter', 'Apple system', 'Segoe Ui', Roboto, Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500;
    color: ${lightColors.text.base};
  }

  :root {
    --border-radius: 0.5rem;
  }

  input:not([class]),
  select {
    #{'-webkit-appearance'}: none;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    #{'-webkit-appearance'}: none;
    margin: 0;
  }

  ::-webkit-resizer {
    display: none;
  }

  [style*='position: relative;'] {
    object {
      opacity: 0;
    }
  }

  fieldset {
    margin: 0;
  }

  iframe {
    border-style: none;
    border-width: 0;
  }

  strong,
  small {
    color: inherit;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`

export default siteStyles
