const LogoFidoCommercialista =  ({ fill }: { fill: string }) => (
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width='180' height='25' viewBox='0 0 180 25'>
  <g id="Layer_3" data-name="Layer 3">
    <g>
      <g>
        <path d="m20.83,18.8s-.08-.02-.12-.05c-.09-.06-.11-.19-.03-.28,1.45-1.91,2.22-4.19,2.27-6.57,0-.11.09-.2.2-.19.11,0,.19.09.19.2-.05,2.47-.86,4.81-2.34,6.8-.03.05-.09.08-.15.08Z"  fill={fill}/>
        <path d="m5.19,23.96s-.05,0-.06-.02c-.09-.03-.15-.15-.11-.25l.76-1.96c-.37-.22-.74-.46-1.09-.74C2.23,19.09.64,16.33.2,13.28c-.45-3.07.35-6.12,2.2-8.59C6.27-.43,13.6-1.46,18.71,2.41c1.79,1.36,3.15,3.18,3.92,5.27h3.62c.09,0,.19.08.19.17.02.09.2,2.39-1.36,3.56-.28.22-.85.42-1.88.68-.51.12-1.06.25-1.65.39-1.8.4-3.87.86-5.4,1.53-.6.26-1.03.69-1.26,1.3-.68,1.74.49,4.38.51,4.41l1.02,2.14s.03.11,0,.15c-.02.05-.06.09-.11.11-.06.03-1.67.72-3.92.94-1.7.17-4.06.06-6.28-1.11l-.74,1.91c-.05.06-.11.11-.19.11Zm1.05-2.41c2.16,1.16,4.44,1.25,6.09,1.09,1.74-.17,3.15-.65,3.62-.83l-.94-1.96c-.05-.12-1.26-2.79-.52-4.7.26-.69.77-1.2,1.46-1.51,1.56-.68,3.64-1.14,5.47-1.54.6-.14,1.17-.26,1.65-.39.6-.15,1.45-.37,1.74-.6,1.16-.88,1.22-2.51,1.22-3.05h-7.46c-.08,0-.15-.05-.19-.12-.02-.05-1.7-4.53-5.74-4.12-4.95.49-6.63,5.29-8.1,9.53-.2.6-.4,1.16-.6,1.7.2.2.74.76,1.48,1.16.8.45,1.56.59,2.28.45l3.07-7.97c.03-.08.12-.14.22-.12.09.02.15.09.17.17.02.25.4,6.08-2.2,7.8-.31.22-.63.35-.94.45l-1.79,4.58ZM11.7.45C8.28.45,4.9,1.99,2.69,4.92.89,7.31.13,10.26.55,13.23c.43,2.95,1.96,5.6,4.35,7.46.32.26.66.48,1,.68l1.65-4.32c-2.13.26-3.95-1.8-3.96-1.83-.05-.05-.06-.12-.03-.2.22-.57.43-1.17.65-1.79,1.53-4.35,3.22-9.27,8.42-9.79,1.79-.19,3.44.51,4.72,2.02.8.93,1.23,1.87,1.37,2.2h3.48c-.76-1.97-2.04-3.7-3.75-4.98C16.45,1.18,14.06.45,11.7.45Zm-.91,9.25l-2.61,6.8c.19-.08.37-.17.56-.29,1.8-1.2,2.05-4.78,2.05-6.51Z"  fill={fill}/>
        <circle cx="15.72" cy="7.05" r=".42" fill={fill} />
        <path d="m26.18,8.95c-.31,0-.56-.25-.56-.56s.25-.56.56-.56"  fill={fill}/>
      </g>
      <g>
        <path d="m35.14,8.82v2.54h5.04v1.14h-5.04v4.21h-1.3V7.65h6.69v1.17h-5.4Z"  fill={fill}/>
        <path d="m41.98,7.84c0-.45.37-.79.82-.79s.8.34.8.79-.35.8-.8.8c-.46,0-.82-.35-.82-.8Zm.2,1.51h1.23v7.37h-1.23v-7.37Z"  fill={fill}/>
        <path d="m52.99,7.39v9.31h-1.23v-1.68c-.52,1.05-1.51,1.88-3.04,1.88-2.04,0-3.52-1.67-3.52-3.92s1.6-3.95,3.69-3.95c1.37,0,2.31.71,2.87,1.77v-3.42h1.23Zm-1.19,5.54c0-1.56-1.03-2.73-2.68-2.73s-2.62,1.19-2.62,2.78,1.02,2.79,2.59,2.79c1.65.02,2.71-1.23,2.71-2.84Z"  fill={fill}/>
        <path d="m54.73,12.99c0-2.2,1.57-3.93,3.93-3.93s3.93,1.7,3.93,3.93-1.56,3.93-3.93,3.93c-2.34-.02-3.93-1.74-3.93-3.93Zm6.58,0c0-1.6-1.02-2.78-2.65-2.78s-2.65,1.17-2.65,2.78,1.02,2.78,2.65,2.78,2.65-1.19,2.65-2.78Z"  fill={fill}/>
        <path d="m63.92,12.2c0-2.76,2.1-4.78,5.12-4.78,1.36,0,2.44.35,3.24.94v.88c-.83-.62-1.85-1.03-3.21-1.03-2.59,0-4.27,1.71-4.27,3.98s1.63,4.01,4.21,4.01c1.33,0,2.44-.43,3.32-1.16v.85c-.96.77-2.07,1.11-3.39,1.11-3.05-.03-5.01-2.13-5.01-4.8Z"  fill={fill}/>
        <path d="m73.51,12.99c0-2.19,1.6-3.92,3.89-3.92s3.87,1.7,3.87,3.92-1.57,3.93-3.87,3.93c-2.27-.02-3.89-1.74-3.89-3.93Zm6.94,0c0-1.8-1.22-3.18-3.05-3.18s-3.05,1.37-3.05,3.18,1.22,3.19,3.05,3.19,3.05-1.4,3.05-3.19Z"  fill={fill}/>
        <path d="m93.59,12.01v4.69h-.79v-4.53c0-1.51-.65-2.36-1.9-2.36-1.37,0-2.1,1.09-2.1,2.47v4.44h-.79v-4.75c0-1.3-.66-2.17-1.9-2.17-1.33,0-2.1,1.16-2.1,2.65v4.27h-.8v-7.39h.79v1.6c.4-1.23,1.2-1.87,2.36-1.87s1.99.71,2.28,1.83c.39-1.17,1.23-1.83,2.45-1.83,1.57,0,2.48,1.08,2.48,2.95Z"  fill={fill}/>
        <path d="m106.42,12.01v4.69h-.79v-4.53c0-1.51-.65-2.36-1.9-2.36-1.37,0-2.1,1.09-2.1,2.47v4.44h-.79v-4.75c0-1.3-.66-2.17-1.9-2.17-1.33,0-2.1,1.16-2.1,2.65v4.27h-.79v-7.39h.79v1.6c.4-1.23,1.2-1.87,2.36-1.87s1.99.71,2.28,1.83c.39-1.17,1.23-1.83,2.45-1.83,1.56,0,2.47,1.08,2.47,2.95Z"  fill={fill}/>
        <path d="m109.07,12.89v.06c.02,1.93,1.23,3.22,3.22,3.22,1.19,0,2.14-.46,2.78-1v.77c-.71.59-1.73.97-2.9.97-2.36,0-3.93-1.63-3.93-3.98,0-2.16,1.39-3.87,3.73-3.87,2.1,0,3.32,1.31,3.32,3.32v.51h-6.21Zm.05-.62h5.41c-.03-1.46-.91-2.47-2.59-2.47-1.63,0-2.62,1.02-2.82,2.47Z"  fill={fill}/>
        <path d="m121.14,9.47v.8c-.28-.2-.62-.29-1.06-.29-1.2,0-2.02,1-2.02,2.5v4.24h-.8v-7.37h.8v1.76c.34-1.22,1.13-1.88,2.16-1.88.37-.02.68.08.93.25Z"  fill={fill}/>
        <path d="m121.75,13.02c0-2.22,1.59-3.93,4.09-3.93,1.25,0,2.05.37,2.64.82v.83c-.65-.54-1.46-.91-2.59-.91-2.05,0-3.3,1.39-3.3,3.19s1.2,3.18,3.32,3.18c1.09,0,1.94-.4,2.59-.91v.77c-.72.56-1.59.88-2.71.88-2.48-.03-4.02-1.74-4.02-3.92Z"  fill={fill}/>
        <path d="m130.21,7.88c0-.29.25-.52.56-.52s.56.22.56.52-.23.56-.56.56c-.31.02-.56-.23-.56-.56Zm.17,1.46h.79v7.37h-.79v-7.37Z"  fill={fill}/>
        <path d="m139.25,12.14v4.58h-.79v-1.33c-.43.96-1.42,1.53-2.59,1.53-1.6,0-2.71-1.05-2.71-2.41s1.11-2.36,2.78-2.36c1.2,0,2.13.54,2.51,1.34v-1.23c0-1.59-1-2.44-2.47-2.44-1.11,0-1.87.43-2.48,1.09v-.85c.56-.49,1.39-.99,2.62-.99,1.91-.03,3.13,1.06,3.13,3.05Zm-.79,2.28c0-.99-.96-1.68-2.25-1.68-1.4,0-2.24.74-2.24,1.76s.86,1.76,2.19,1.76c1.31-.02,2.3-.83,2.3-1.83Z"  fill={fill}/>
        <path d="m141.71,7.39h.79v9.31h-.79V7.39Z"  fill={fill}/>
        <path d="m144.97,7.88c0-.29.25-.52.56-.52s.56.22.56.52-.23.56-.56.56c-.31.02-.56-.23-.56-.56Zm.17,1.46h.79v7.37h-.79v-7.37Z"  fill={fill}/>
        <path d="m147.91,15.84v-.82c.89.79,1.97,1.19,3.13,1.19,1.31,0,2.14-.52,2.14-1.46,0-.54-.26-1.13-1.45-1.42l-1.76-.4c-1.48-.37-1.99-1.06-1.99-1.85,0-1.19,1.14-1.99,2.93-1.99,1.23,0,2.1.4,2.71.83v.79c-.71-.56-1.6-.94-2.73-.94-1.34,0-2.13.48-2.13,1.3,0,.54.39.96,1.51,1.22l1.7.37c1.37.32,2.02,1.03,2.02,2.05,0,1.36-1.2,2.2-2.93,2.2-1.25,0-2.36-.43-3.16-1.06Z"  fill={fill}/>
        <path d="m159.92,9.99h-2.27v6.72h-.8v-6.72h-2.14v-.66h2.14v-1.57l.8-.28v1.85h2.25v.66h.02Z"  fill={fill}/>
        <path d="m166.68,12.14v4.58h-.79v-1.33c-.43.96-1.42,1.53-2.59,1.53-1.6,0-2.71-1.05-2.71-2.41s1.11-2.36,2.78-2.36c1.2,0,2.13.54,2.51,1.34v-1.23c0-1.59-1-2.44-2.47-2.44-1.11,0-1.87.43-2.48,1.09v-.85c.56-.49,1.39-.99,2.62-.99,1.91-.03,3.13,1.06,3.13,3.05Zm-.79,2.28c0-.99-.96-1.68-2.25-1.68-1.4,0-2.24.74-2.24,1.76s.86,1.76,2.19,1.76c1.3-.02,2.3-.83,2.3-1.83Z"  fill={fill}/>
        <path d="m168.53,16.21c0-.34.26-.62.63-.62s.62.28.62.62c0,.37-.26.65-.62.65-.37,0-.63-.29-.63-.65Z"  fill={fill}/>
        <path d="m171.66,7.88c0-.29.25-.52.56-.52s.56.22.56.52-.23.56-.56.56c-.31.02-.56-.23-.56-.56Zm.17,1.46h.79v7.37h-.79v-7.37Z"  fill={fill}/>
        <path d="m179.26,9.99h-2.25v6.72h-.8v-6.72h-2.14v-.66h2.14v-1.57l.8-.28v1.85h2.25v.66Z"  fill={fill}/>
      </g>
    </g>
  </g>
</svg>
)


export default LogoFidoCommercialista;
