import styled from '@emotion/styled'
import Image, { StaticImageData } from 'next/image'
import { baseColors, lightColors } from '@totmoney/colors'
import { mq } from '@totmoney/grid'

import Grid from 'components/Grid'
import { H2, H4, Paragraph } from 'components/Typography'
import type { LinkInfo } from 'components/LinkComponent'
import Stack from 'components/Stack'

import CardBackground from 'public/illustrations/card-background.png'
import { LinkTrackEvent } from 'helpers/events/components/LinkTrackEvent'

const shadowColor = '#1D1D1B14'

const CardWrapper = styled.div<{ twin?: boolean }>(({ twin }) => ({
  gridColumnEnd: 'span 4',
  borderRadius: '1rem',
  boxShadow: `0px 4px 24px 0px ${shadowColor} `,
  backgroundColor: lightColors.background.tint,
  overflow: 'hidden',

  '&:hover': {
    boxShadow: `0px 8px 32px 0px ${shadowColor} `
  },

  [mq.xs]: {
    gridColumnEnd: 'span 6'
  },
  [mq.md]: {
    gridColumnEnd: twin ? 'span 6' : 'span 4'
  }
}))

const CardStack = styled(Stack)<{ small?: boolean }>(({ small }) => ({
  padding: '2.5rem 2rem',
  height: '100%',

  [mq.xs]: {
    padding: small ? '2rem 2rem' : '4rem 3.5rem'
  }
}))

const ButtonWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '1.5rem',

  [mq.xs]: {
    flexDirection: 'row',
    gap: '2.5rem'
  }
})

const CardContent = styled(Paragraph)({
  ul: {
    paddingBlock: '.5rem',
    display: 'flex',
    gap: '.5rem',
    flexDirection: 'column',

    li: {
      listStyleImage: 'url(/illustrations/check.svg)'
    }
  }
})

const ImageWrapper = styled.div({
  position: 'relative',
  overflow: 'visible',
  minHeight: '15rem',
  margin: 'auto -2rem -2.5rem',

  [mq.sm]: {
    minHeight: '20rem',
    margin: 'auto -4rem -4rem'
  }
})

const Card = ({
  elements,
  small,
  twin,
  title,
  hasImage
}: {
  title?: string
  small?: boolean
  twin?: boolean
  hasImage?: boolean
  elements: {
    subtitle?: string
    title: string
    text: string
    links?: LinkInfo[]
  }[]
}) => {
  return (
    <Grid css={{ rowGap: '2rem' }}>
      {title ? (
        <H2
          css={{
            gridColumn: '1/-1',
            textAlign: 'center',
            paddingBlockEnd: '4rem',
            [mq.xs]: { gridColumn: '3/11' }
          }}
        >
          {title}
        </H2>
      ) : null}
      {elements.map((el) => (
        <CardWrapper key={el.title} twin={twin}>
          <CardStack small={small}>
            <div>
              {el.subtitle ? (
                <Paragraph as='h2' weight='bold' css={{ color: baseColors.liliac[700], paddingBlockEnd: '1rem' }}>
                  {el.subtitle}
                </Paragraph>
              ) : null}
              {!small ? <H2 as='p'>{el.title}</H2> : <H4 as='h3'>{el.title}</H4>}
            </div>
            <CardContent as='div' dangerouslySetInnerHTML={{ __html: el.text }} />
            {el.links ? (
              <ButtonWrapper>
                {el.links.map((link) => (
                  <LinkTrackEvent
                    type='linkWithIcon'
                    href={link.url}
                    key={link.url}
                    ctaText={link.label}
                    {...link}
                    icon='link'
                  />
                ))}
              </ButtonWrapper>
            ) : null}
            {hasImage ? (
              <ImageWrapper>
                <Image src={CardBackground} alt='Text' fill style={{ objectFit: 'cover' }} />
              </ImageWrapper>
            ) : null}
          </CardStack>
        </CardWrapper>
      ))}
    </Grid>
  )
}

export default Card
