import { createContext, useContext } from 'react'

export type ValidationMessages = Record<string, string>

const ValidationMessagesContext = createContext<null | ValidationMessages>(null)

export const useValidationMessages = () => {
  const validationMessages = useContext(ValidationMessagesContext)

  if (!validationMessages) {
    throw new Error('useValidationMessages: Cannot use this hook outside its context')
  }

  return validationMessages
}

const ValidationMessagesProvider = ({
  messages,
  children,
}: {
  messages: ValidationMessages
  children: React.ReactNode
}) => (
  <ValidationMessagesContext.Provider value={messages}>
    {children}
  </ValidationMessagesContext.Provider>
)

export default ValidationMessagesProvider
