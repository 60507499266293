import styled from '@emotion/styled'
import { lightColors } from '@totmoney/colors'
import { spaces } from '@totmoney/spaces'
import { FC, PropsWithChildren } from 'react'

const Slider = styled.div`
  display: inline-flex;
  gap: ${spaces.xl};
  overflow: hidden;
  flex-wrap: nowrap;
  width: 100%;
  mask-image: ${({ theme }) =>
    `linear-gradient(to right, transparent 0, ${(theme.colors ?? lightColors).background.tint} 3rem, ${
      (theme.colors ?? lightColors).background.tint
    } calc(100% - 3rem), transparent 100%)`};
`

const Slide = styled.div`
  display: flex;
  animation: infinite-scroll 14s linear infinite;

  @keyframes infinite-scroll {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-106.8%);
    }
  }
`

export const InfiniteLoopSlider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Slider>
      <Slide>{children}</Slide>
      <Slide aria-hidden='true'>{children}</Slide>
      <Slide aria-hidden='true'>{children}</Slide>
      <Slide aria-hidden='true'>{children}</Slide>
    </Slider>
  )
}
