import Head from 'next/head'
import { isFaq } from './buildPage'
import { ComponentFaq, LandingPagePageElementsItem } from './api/schema'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'

/*
 * https://schema.org/
 * we import types from 'schema-dts', a Google-maintained library
 * SomethingInput types are used by out composer functions
 */

import type {
  ImageObject,
  Person,
  PostalAddress,
  FAQPage,
  Organization,
  Article,
  Question,
  NewsArticle,
  BlogPosting,
  Graph,
  BreadcrumbList,
  ListItem,
  WebSite,
} from 'schema-dts'

/*
 * Defining an Breadcrumb Type
 */

type BreadcrumbInput = {
  label: string
  url: string
}

const printBreadcrumb = (item: BreadcrumbInput[]): BreadcrumbList => {
  return {
    '@type': 'BreadcrumbList',
    itemListElement: item.map(
      (item: BreadcrumbInput, index: number): ListItem => ({
        '@type': 'ListItem',
        position: index + 1,
        name: item.label,
        item: item.url,
      })
    ),
  }
}

/*
 * Defining an Image Type
 */

type ImageInput = {
  imageUrl: string
  height: number
  width: number
  caption?: string
  id?: string
  locale?: string
}

const printImage = ({ imageUrl, height, width, caption, id, locale }: ImageInput): ImageObject => {
  return {
    '@type': 'ImageObject',
    url: encodeURI(imageUrl),
    contentUrl: encodeURI(imageUrl),
    width: {
      '@type': 'QuantitativeValue',
      unitCode: 'E37',
      value: width,
    },
    height: {
      '@type': 'QuantitativeValue',
      unitCode: 'E37',
      value: height,
    },
    ...(caption !== undefined && { caption: caption }),
    ...(locale !== undefined && { inLanguage: locale }),
    ...(id !== undefined && { '@id': id }),
  }
}

/*
 * Defining a Person Type
 */

type PersonInput = {
  name: string
  url: string
  jobTitle?: string
  image?: ImageInput
}

const printPerson = ({ name, url, jobTitle, image }: PersonInput): Person => {
  return {
    '@type': 'Person',
    name: name,
    url: encodeURI(url),
    ...(typeof jobTitle !== 'undefined' && { jobTitle: jobTitle }),
    ...(typeof image !== 'undefined' && { image: printImage(image) }),
  }
}

/*
 * Defining an Address Type
 */

type AddressInput = {
  locality: string
  country: string
  postalCode: string
}

const printAddress = ({ locality, country, postalCode }: AddressInput): PostalAddress => {
  return {
    '@type': 'PostalAddress',
    addressLocality: locality,
    addressCountry: country,
    postalCode: postalCode,
  }
}

/*
 * Defining an FAQ Type
 */

type TFaqItem = {
  question: string
  answer: string
}

const printFAQ = (items: TFaqItem[]): FAQPage => {
  return {
    '@type': 'FAQPage',
    mainEntity: items.map(
      (item: TFaqItem, i: number): Question => ({
        '@type': 'Question',
        name: item.question,
        acceptedAnswer: {
          '@type': 'Answer',
          text: item.answer,
        },
      })
    ),
  }
}

/*
 * Defining the Website reponse
 */

const printWebsite = (schemaOrgProps:any): WebSite => {
  return {
    '@type': 'WebSite',
    name: schemaOrgProps.openGraphSiteName,
    url: schemaOrgProps.openGraphOrganizationUrl,
  }
}

/*
 * Defining the Organization reponse
 */

const printOrganization = (schemaOrgProps:any): Organization => {
  return {
    '@type': 'Organization',
    name: schemaOrgProps.openGraphOrganizationName,
    url: schemaOrgProps.openGraphOrganizationUrl,
    logo: printImage({
      imageUrl: 'https://tot.money/Logo_Tot_OpenGraph.png',
      height: 415,
      width: 1020,
      caption: schemaOrgProps.openGraphOrganizationName,
    }),
    foundingDate: '2020-12-02',
    address: printAddress({
      locality: 'Milano',
      country: 'IT',
      postalCode: '20124',
    }),
    founder: [
      printPerson({
        name: 'Andrea Susta',
        url: 'https://www.linkedin.com/in/andrea-susta/',
      }),
      printPerson({
        name: 'Doris Messina',
        url: 'https://www.linkedin.com/in/dorismessina/',
      }),
      printPerson({
        name: 'Bruno Reggiani',
        url: 'https://www.linkedin.com/in/breggiani/',
      }),
    ],
    sameAs: [
      'https://www.linkedin.com/company/totmoney/',
      'https://twitter.com/wearetotmoney',
      'https://www.facebook.com/wearetotmoney',
    ],
  }
}

/*
 * Defining an Article Type
 * non-AMP as per Google documentation https://developers.google.com/search/docs/advanced/structured-data/article#article-types
 */

type ArticleInput = {
  type: 'Article' | 'NewsArticle' | 'BlogPosting'
  headline: string
  dateModified: string
  datePublished: string
  authorType: 'Person' | 'Organization'
  author?: PersonInput
  image: ImageInput[]
  mainEntityOfPage?: string
}

const printArticle = ({
  type,
  headline,
  dateModified,
  datePublished,
  authorType,
  author,
  image,
  mainEntityOfPage,
}: ArticleInput, schemaOrgProps:any): Article | NewsArticle | BlogPosting => {
  return {
    '@type': type,
    author:
      authorType == 'Organization' || author == undefined
        ? printOrganization(schemaOrgProps)
        : printPerson(author),
    dateModified: dateModified,
    datePublished: datePublished,
    headline: headline,
    // For best results, provide multiple high-resolution images (minimum of 300,000 pixels when multiplying width and height) with the following aspect ratios: 16x9, 4x3, and 1x1.
    image: image.map((picture: ImageInput, i: number) => printImage(picture)),
    publisher: printOrganization(schemaOrgProps),
    mainEntityOfPage: mainEntityOfPage,
  }
}

/*
 * Output the final JSON-LD schema.org markup
 */
function SchemaOrg(schemaOrgProps:any) {

  function getComponentProps(componentName: string) {
    const array = schemaOrgProps.data
    const key = 'component'
    try {
      for (var i = 0; i < array.length; i++) {
        if (array[i][key] === componentName) {
          return array[i]
        }
      }
    } catch {
      return undefined
    }
    return undefined
  }

  type schemaOrgInput = {
    FAQitems: TFaqItem[] | undefined
    article: ArticleInput | undefined
    //  breadcrumbsArray?:BreadcrumbInput[] | undefined
  }
  let config: schemaOrgInput = {
    FAQitems: undefined,
    article: undefined,
    //breadcrumbsArray:undefined
  }

  // extract useful information from schemaOrgProps and translate it to config object
  //FAQ
  const faqPropsOld = schemaOrgProps?.data?.faq?.items
  const faqPropsNew = getComponentProps('Faq')?.data.elements
  const faqPropsComponent: ComponentFaq | undefined = schemaOrgProps?.components?.find(
    (el: LandingPagePageElementsItem) => isFaq(el)
  )

  if (faqPropsComponent) {
    config.FAQitems = faqPropsComponent.faqListCollection.items.map((faq) => ({
      question: faq.question,
      answer: documentToPlainTextString(faq.answer.json),
    }))
  } else if (typeof faqPropsNew !== 'undefined') {
    config.FAQitems = faqPropsNew
  } else if (typeof faqPropsOld !== 'undefined') {
    config.FAQitems = faqPropsOld
  }

  //Article
  if (schemaOrgProps.hasOwnProperty('type') && schemaOrgProps.type == 'blogPost') {
    config.article = {
      type: 'BlogPosting',
      headline: schemaOrgProps.data.headline,
      // check specific ID for the Orgnanization author: '7v3leHCsgkivhtrn5AvEjR'
      authorType:
        schemaOrgProps.data.author && schemaOrgProps.data.author.sys?.id !== '7v3leHCsgkivhtrn5AvEjR'
          ? 'Person'
          : 'Organization',
      dateModified: schemaOrgProps.data.updatedAt,
      datePublished: schemaOrgProps.data.createdAt,
      author: schemaOrgProps.data.author
        ? {
            name: schemaOrgProps.data.author.name,
            url: schemaOrgProps.data.author.url,
            // jobTitle: schemaOrgProps.data.author.role,
            // image: {
            //   imageUrl: schemaOrgProps.data.author.picture.url,
            //   width: 256,
            //   height: 256,
            // },
          }
        : undefined,
      image: [
        // using Contentful APIs to crop to correct resolution
        // https://developers.google.com/search/docs/appearance/structured-data/article#article-types
        {
          // 16x9
          imageUrl: `${schemaOrgProps.data.image.src}?fit=crop&w=1184&h=666`,
          width: 1184,
          height: 666,
          caption: schemaOrgProps.data.image.alt,
        },
        {
          // 4x3
          imageUrl: `${schemaOrgProps.data.image.src}?fit=crop&w=1084&h=813`,
          width: 1084,
          height: 813,
          caption: schemaOrgProps.data.image.alt,
        },
        {
          // 1x1
          imageUrl: `${schemaOrgProps.data.image.src}?fit=crop&w=958&h=958`,
          width: 958,
          height: 958,
          caption: schemaOrgProps.data.image.alt,
        },
      ],
      // FIXME: Improve url retrieval method?
      mainEntityOfPage: `https://tot.money/blog/${schemaOrgProps.data.slug}`,
    }
  }
  // print the final output using the config object
  const output: Graph = {
    '@context': 'https://schema.org',
    '@graph': [
      printWebsite(schemaOrgProps),
      printOrganization(schemaOrgProps),
      // printBreadcrumb(config.breadcrumbsArray),
      ...(config.FAQitems !== undefined ? [printFAQ(config.FAQitems)] : []),
      ...(config.article !== undefined ? [printArticle(config.article, schemaOrgProps)] : []),
    ],
  }

  return (
    <Head>
      <script
        type='application/ld+json'
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(output),
        }}
      />
    </Head>
  )
}

export default SchemaOrg
