import { CSSObject } from '@emotion/react'
import { baseColors } from '@totmoney/colors'
import { mq } from '@totmoney/grid'
import BACKGROUNDS from 'data/backgrounds'

const getPadding = (
  paddingY: [number, number],
  paddingTop?: [number, number],
  paddingBottom?: [number, number]
) => ({
  paddingTop: `${(paddingTop ? paddingTop[0] : paddingY[0]) * 2}rem`,
  paddingBottom: `${(paddingBottom ? paddingBottom[0] : paddingY[0]) * 2}rem`,

  [mq.xs]: {
    paddingTop: `${(paddingTop ? paddingTop[1] : paddingY[1]) * 2}rem`,
    paddingBottom: `${(paddingBottom ? paddingBottom[1] : paddingY[1]) * 2}rem`,
  },
})

export const themes: Record<string, CSSObject & { backgroundColor: string }> = {
  red: {
    backgroundColor: BACKGROUNDS['red'],
  },
  grey: {
    backgroundColor: BACKGROUNDS['grey'],
  },
  blue: {
    backgroundColor: BACKGROUNDS['blue'],
  },
  // TOFIX: lilac from Contentful lilIac from components?
  lilac: {
    backgroundColor: BACKGROUNDS['liliac'],
    '--accent': baseColors.liliac[600],
  },
  // TOFIX: Check possible dark theme discrepancies
  dark: {
    backgroundColor: baseColors.neutral[800],
  },
}

const Wrapper = ({
  children,
  bgImg,
  bgColor,
  paddingY,
  paddingTop,
  paddingBottom,
  theme,
  overflow,
  className,
}: {
  children?: React.ReactNode
  bgColor?: string
  bgImg?: string
  paddingY?: [number, number]
  paddingTop?: [number, number]
  paddingBottom?: [number, number]
  theme?: keyof typeof themes
  overflow?: boolean
  className?: string
}) => {
  const paddingStyle = getPadding(paddingY || [2, 3], paddingTop, paddingBottom)
  const bgStyle = {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    [mq.xs]: {
      backgroundSize: 'unset',
    },
  }
  return (
    <div
      css={[
        { overflow: overflow ? undefined : 'hidden' },
        paddingStyle,
        { backgroundImage: bgImg ? `url(${bgImg})` : 'unset' },
        bgImg ? bgStyle : null,
        bgColor ? { backgroundColor: bgColor } : null,
        theme && theme !== 'white' ? themes[theme] : null,
      ]}
      data-theme={theme}
      className={className}
    >
      {children}
    </div>
  )
}

export default Wrapper
