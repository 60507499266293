// A tendere saranno sostituiti con quelli da components
const BACKGROUNDS = {
  grey: '#FBFBFB',
  blue: '#F7FBFF',
  liliac: '#FAF7FF',
  red: '#FFF7F8',
  green: '#F7FFF8',
  yellow: '#FFFCF7',
  white: '#FFFFFF',
}

export default BACKGROUNDS
