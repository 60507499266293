// @ts-nocheck
/**
 * This component handles the scripts shared by every page,
 * add any new script here using the `<Script>` component.
 * Prioritization and position in the document is handled by next.js,
 * but can be controlled with the `strategy` prop, accepting the following values:
 * - `beforeInteractive` -> ASAP, injected server-side, runs before next.js scripts and runtime (critical stuff)
 * - `afterInteractive`  -> The default, runs immediately after next.js hydration (analytics)
 * - `lazyOnload`        -> Runs at idle time (external non-essential widgets)
 *
 * More info at: https://nextjs.org/docs/basic-features/script
 *
 */
import Script from 'next/script'
import { sleepUntil } from 'helpers/sleepUntil'

/**
 *  Intercom
 *  https://www.intercom.com/help/en/articles/170-integrate-intercom-in-a-single-page-app
 */
type IntercomActions = 'update' | 'show' | 'hide'

export const intercomAction = (action : IntercomActions) => {
  sleepUntil(() => typeof window.Intercom !== 'undefined', 5000)
    .then(() => {
      // ready
      if (action === 'update') {window.Intercom('update', { last_request_at: parseInt(new Date().getTime() / 1000) })}
      if (action === 'show') {window.Intercom('show')}
      if (action === 'hide') {window.Intercom('hide')}
    })
    .catch(() => {
      // timeout
    })
}

const Intercom = () => {
  return (
    <>
      <Script
        id={process.env.trackingScripts.intercom.hashedId}
        strategy='lazyOnload'
        src={process.env.trackingScripts.intercom.hashedSrc}
      />
    </>
  )
}

export default Intercom