const LogoGooglePay = ({ fill }: { fill: string }) => (
  <svg width='58' height='25' viewBox='0 0 58 25' xmlns='http://www.w3.org/2000/svg' fill={fill}>
    <path d='M57.8326 7.53299L50.2839 24.3996H47.949L50.7538 18.4973L45.784 7.53118H48.24L51.8363 15.9365H51.8833L55.3658 7.53118L57.8326 7.53299ZM26.4705 12.7486V19.5816H24.0977V2.715H30.3759C31.8662 2.67415 33.3174 3.19467 34.442 4.17341C35.5535 5.08605 36.1643 6.37638 36.1462 7.74082C36.1539 8.42747 36.0043 9.10676 35.7089 9.72667C35.4135 10.3466 34.9802 10.8907 34.442 11.3173C33.3505 12.2769 31.9951 12.7486 30.3759 12.7486H26.4705ZM26.4615 4.79509V10.6775H30.4337C31.2985 10.7085 32.1406 10.3975 32.7776 9.81186C33.3903 9.26609 33.7282 8.52153 33.7282 7.74985C33.7282 6.96914 33.3885 6.22458 32.7776 5.68784C32.4657 5.39091 32.0978 5.15893 31.6954 5.00536C31.293 4.85179 30.8642 4.7797 30.4337 4.79328L26.4615 4.79509ZM40.8992 7.53299C42.4046 7.53299 43.6009 7.95587 44.4756 8.79441C45.3503 9.63295 45.784 10.7805 45.784 12.2389V19.2129H43.8268V17.6406H43.7419C42.8943 18.931 41.7738 19.5798 40.3715 19.5798C39.1661 19.5798 38.1685 19.2129 37.3589 18.4683C36.9682 18.1226 36.6573 17.6961 36.4476 17.2184C36.238 16.7407 36.1345 16.2231 36.1444 15.7015C36.1444 14.5341 36.5673 13.6034 37.4239 12.9058C38.2715 12.2191 39.41 11.8703 40.8305 11.8703C42.0449 11.8703 43.0425 12.1052 43.8232 12.566V12.0763C43.8257 11.7208 43.7522 11.3688 43.6076 11.044C43.463 10.7193 43.2506 10.4292 42.9847 10.1932C42.4491 9.69182 41.7412 9.41554 41.0076 9.42151C39.8691 9.42151 38.9655 9.9203 38.2968 10.9179L36.4986 9.75042C37.4872 8.27213 38.9546 7.52938 40.8937 7.52938L40.8992 7.53299ZM38.2552 15.7503C38.2512 16.0181 38.3105 16.2831 38.4282 16.5237C38.546 16.7642 38.7189 16.9736 38.9329 17.1346C39.3847 17.5015 39.9504 17.7003 40.5233 17.6894C41.3799 17.6894 42.2076 17.3316 42.8202 16.7009C43.469 16.0991 43.8377 15.2515 43.8377 14.366C43.1979 13.8383 42.3124 13.5744 41.1739 13.5744C40.3353 13.5744 39.6486 13.7823 39.0938 14.1961C38.539 14.619 38.2552 15.1286 38.2552 15.7485V15.7503Z' />
    <path d='M19.0893 8.15036H18.3129L9.63838 8.11036V11.9657H15.0855C14.2908 14.21 12.1554 15.8211 9.63838 15.8211C6.4447 15.8211 3.85535 13.2317 3.85535 10.038C3.85535 6.84436 6.4447 4.25501 9.63838 4.25501C11.1126 4.25501 12.4538 4.81114 13.4749 5.71956L16.2012 2.99335C14.4797 1.38904 12.1771 0.399658 9.63838 0.399658C4.31558 0.399658 0 4.71524 0 10.038C0 15.3608 4.31558 19.6764 9.63838 19.6764C14.9612 19.6764 19.2768 15.3608 19.2768 10.038C19.2768 9.39179 19.2103 8.76095 19.0893 8.15036Z' />
    <path d='M1.11133 5.55185L4.27802 7.87422C5.13487 5.75281 7.21001 4.25501 9.6384 4.25501C11.1126 4.25501 12.4538 4.81114 13.475 5.71956L16.2012 2.99335C14.4798 1.38904 12.1772 0.399658 9.6384 0.399658C5.9363 0.399658 2.72576 2.48974 1.11133 5.55185Z' />
    <path d='M9.63916 19.6764C12.1288 19.6764 14.3909 18.7236 16.1012 17.1743L13.1181 14.65C12.1179 15.4106 10.8957 15.822 9.63916 15.821C7.13222 15.821 5.00358 14.2225 4.20167 11.9917L1.05859 14.4133C2.65375 17.5347 5.89321 19.6764 9.63916 19.6764Z' />
    <path d='M19.0896 8.1506H18.3132L9.63867 8.1106V11.9659H15.0858C14.7057 13.0341 14.0209 13.9674 13.1162 14.6507L13.1176 14.6498L16.1007 17.174C15.8896 17.3658 19.2771 14.8575 19.2771 10.0383C19.2771 9.39202 19.2105 8.76119 19.0896 8.1506Z' />
  </svg>
)

export default LogoGooglePay
