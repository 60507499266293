import { useEffect } from 'react'
import { useRouter } from 'next/router'
import App, { AppContext, AppInitialProps, AppProps } from 'next/app'
import { Global } from '@emotion/react'

import SchemaOrg from 'helpers/generateSchemaOrg'
import siteStyles from 'styles/global'
import GoogleTag, { gtagPageView } from 'components/scripts/GoogleTag'
import Intercom, { intercomAction } from 'components/scripts/Intercom'
import OneTrustCookieConsent from 'components/scripts/OneTrustCookieConsent'
import ValidationMessagesProvider, { ValidationMessages } from 'components/context/ValidationMessagesContext'
import getConfig from 'helpers/api/getConfig'
import StringsProvider, { Strings } from 'components/context/StringsContext'
import { initializeGTM } from 'helpers/gtm'
import Script from 'next/script'

type AppOwnProps = { validationMessages: ValidationMessages; strings: Strings }

function MyApp({ Component, pageProps, validationMessages, strings }: AppProps & AppOwnProps) {
  const router = useRouter()
  useEffect(() => {
    const onPageChange = () => {
      /*
     Events to be called on every page change
     */

      if (window._lcSensor && window._lcSensor.singlePageNavigation) {
        window._lcSensor.singlePageNavigation()
      }
      gtagPageView()
      intercomAction('update')
    }

    router.events.on('routeChangeComplete', onPageChange)
    return () => {
      router.events.off('routeChangeComplete', onPageChange)
    }
  }, [router.events])

  //Initialize and run Google Tag Manager script
  useEffect(() => {
    initializeGTM()
  }, [])

  return (
    <StringsProvider strings={strings}>
      <ValidationMessagesProvider messages={validationMessages}>
        <OneTrustCookieConsent />
        <GoogleTag />
        <Intercom />
        <Global styles={siteStyles} />
        <Component {...pageProps} />
        <SchemaOrg {...pageProps} {...strings} />
      </ValidationMessagesProvider>
      <Script id='single-page-navigation' strategy='afterInteractive'>
        {`
              if (window._lcSensor && window._lcSensor.singlePageNavigation) {
                window._lcSensor.singlePageNavigation();
              }
            `}
      </Script>
    </StringsProvider>
  )
}

MyApp.getInitialProps = async (context: AppContext): Promise<AppOwnProps & AppInitialProps> => {
  const ctx = await App.getInitialProps(context)
  const { configuration: validationMessages } = await getConfig({
    configId: '5Dv9jCHvWBcJ5JMOo30YKv' // Contentful Entry ID for email validation 'componentConfiguration'
  })
  const { configuration: strings } = await getConfig({
    configId: '4tDDjZDWdLRF4lDPzQjfqV'
  }) // Contentful Entry ID for generic strings 'componentConfiguration'

  return { ...ctx, validationMessages, strings }
}

export default MyApp
