import * as Sentry from '@sentry/nextjs'
import { DocumentNode, print } from 'graphql'
import { InputMaybe } from './api/schema'

const CONTENTFUL_SPACE_ID = process.env.CONTENTFUL_SPACE_ID
const CONTENTFUL_ACCESS_TOKEN = process.env.CONTENTFUL_ACCESS_TOKEN
const CONTENTFUL_PREVIEW_ACCESS_TOKEN = process.env.CONTENTFUL_PREVIEW_ACCESS_TOKEN
const CONTENTFUL_ENVIRONMENT = process.env.CONTENTFUL_ENVIRONMENT || 'master'

async function fetchContent<T, Q extends { preview?: InputMaybe<boolean> } = { preview: false }>(
  query: string | DocumentNode,
  variables?: Q
) {
  const previewMode = variables?.preview || false

  try {
    const res = await fetch(
      `https://graphql.contentful.com/content/v1/spaces/${CONTENTFUL_SPACE_ID}/environments/${CONTENTFUL_ENVIRONMENT}`,
      {
        method: 'POST',
        body: JSON.stringify({
          query: typeof query === 'string' ? query : print(query),
          variables
        }),
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${previewMode === true ? CONTENTFUL_PREVIEW_ACCESS_TOKEN : CONTENTFUL_ACCESS_TOKEN}`
        }
      }
    ).then((res) => res.json())

    // TODO: Handle Contentful errors
    // console.log(res.data.errors)

    return res.data as T
  } catch (error) {
    Sentry.captureException(error)
    console.log(error)
    throw new Error('Error while fetching content from Contentful')
  }
}

export default fetchContent
