import styled from '@emotion/styled'
import { breakpoints, mq } from '@totmoney/grid'
import Image from 'next/image'

import Grid from 'components/Grid'
import Stack from 'components/Stack'
import { DisplayText, Paragraph } from 'components/Typography'
import HeroCardImage from 'public/images/visa-black.webp'
import availableLogos, { AvailableLogos } from 'components/logos'
import { LinkInfo } from './LinkComponent'
import { LinkTrackEvent } from 'helpers/events/components/LinkTrackEvent'

const HeroWrapper = styled(Stack)({
  gap: '1.5rem',
  gridColumn: '-1 / 1',
  position: 'relative',

  [mq.xs]: {
    gridColumn: '3/11',
  },
})

const StyledLinkTrackEvent = styled(LinkTrackEvent)({ margin: 0 })

const LogosWrapper = styled.div({
  display: 'flex',
  gap: '1.5rem',
})

const LongHero = ({
  title,
  secondaryTitle,
  text,
  secondaryText,
  links,
  secondaryLinks,
  logos,
}: {
  title: string
  secondaryTitle?: string
  text: string
  secondaryText?: React.ReactNode
  links?: LinkInfo[]
  secondaryLinks?: LinkInfo[]
  logos?: AvailableLogos[]
}) => (
  <Grid>
    <HeroWrapper>
      <Stack css={{ alignItems: 'center', textAlign: 'center' }}>
        <DisplayText as='h1'>{title}</DisplayText>
        <Paragraph>{text}</Paragraph>
        {links && links[0] ? (
          <StyledLinkTrackEvent type='button' ctaText={links[0].label} contextText={title} href={links[0].url}>
            {links[0].label}
          </StyledLinkTrackEvent>
        ) : null}
      </Stack>
      <div
        css={{
          aspectRatio: `${HeroCardImage.width} / ${HeroCardImage.height}`,
          position: 'relative',
        }}
      >
        <Image
          src={HeroCardImage}
          alt=''
          fill
          quality={100}
          priority
          sizes={`(min-width: ${breakpoints.xl}px) 792px, (min-width: ${breakpoints.lg}px) 632px,  (min-width: ${breakpoints.xs}px) 65vw, 100vw`}
        />
      </div>
      {secondaryText || secondaryTitle || secondaryLinks || logos ? (
        <Stack css={{ alignItems: 'center', textAlign: 'center' }}>
          <DisplayText as='h2'>{secondaryTitle}</DisplayText>
          <Paragraph>{secondaryText}</Paragraph>
          {logos ? (
            <LogosWrapper>
              {logos.map((logo) => {
                const LogoElement = availableLogos[logo]
                return <LogoElement key={logo} fill='#fff' />
              })}
            </LogosWrapper>
          ) : null}
          {secondaryLinks && secondaryLinks[0] ? (
            <StyledLinkTrackEvent type='button' ctaText={secondaryLinks[0].label} contextText={title} href={secondaryLinks[0].url}>
              {secondaryLinks[0].label}
            </StyledLinkTrackEvent>
          ) : null}
        </Stack>
      ) : null}
    </HeroWrapper>
  </Grid>
)

export default LongHero
