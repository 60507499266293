import gql from 'graphql-tag'
import fetchContent from '../fetchContent'
import { GetConfigQuery, GetConfigQueryVariables } from './schema'

const GET_CONFIG = gql`
  query getConfig($configId: String!, $preview: Boolean = false) {
    componentConfigurationCollection(preview: $preview, where: { sys: { id: $configId } }) {
      items {
        sys {
          id
        }
        componentName
        configuration
      }
    }
  }
`

export default async function getConfig(variables: GetConfigQueryVariables) {
  const data = await fetchContent<GetConfigQuery, GetConfigQueryVariables>(GET_CONFIG, variables)
  return data.componentConfigurationCollection?.items[0]
}
