import styled from '@emotion/styled'
import { mq } from '@totmoney/grid'

const Stack = styled.div<{ size?: 'small' }>(({ size }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: size === 'small' ? '1.5rem' : '2rem',

  [mq.xs]: {
    gap: size === 'small' ? '1.5rem' : '2.5rem',
  },
}))

export default Stack
