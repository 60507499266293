const LogoApplePay = ({ fill }: { fill: string }) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" fill={fill} width='60' height='25' viewBox="0 0 233.14 99.47">
		<g>
			<path d="M43.8732,12.6988C46.60624,9.28037,48.46086,4.69039,47.97172,0c-4.00079,0.19894-8.88298,2.63946-11.70954,6.06057
				c-2.53794,2.92966-4.78422,7.7118-4.19863,12.20557C36.55464,18.65572,41.04158,16.02133,43.8732,12.6988"/>
			<path d="M47.92068,19.14357c-6.52215-0.3885-12.06756,3.70163-15.18223,3.70163c-3.11637,0-7.88593-3.50585-13.0446-3.41137
				c-6.71433,0.09862-12.94446,3.89497-16.3517,9.93291c-7.00811,12.07887-1.84944,29.99609,4.96557,39.83367
				c3.30953,4.86696,7.29806,10.22584,12.55359,10.03336c4.96557-0.19486,6.91187-3.2153,12.94775-3.2153
				c6.03144,0,7.78463,3.2153,13.04101,3.1179c5.45099-0.09746,8.85883-4.86939,12.1683-9.74109
				c3.79665-5.54809,5.35085-10.90521,5.44862-11.1993c-0.09777-0.0974-10.51105-4.0922-10.6076-16.07069
				c-0.09831-10.02977,8.17558-14.80048,8.56499-15.09633C57.75206,20.11855,50.45163,19.33935,47.92068,19.14357"/>
		</g>
		<g>
			<path d="M104.71047,5.56995c14.17578,0,24.04687,9.77148,24.04687,23.99805c0,14.27734-10.07421,24.09961-24.40234,24.09961
				H88.65969v24.95996H77.31985V5.56995H104.71047z M88.65969,44.14905h13.01172c9.87305,0,15.49219-5.31543,15.49219-14.53027
				c0-9.21387-5.61914-14.47949-15.44142-14.47949H88.65969V44.14905z"/>
			<path d="M131.72023,63.48987c0-9.31641,7.13867-15.03711,19.79688-15.74609l14.58008-0.86035v-4.10059
				c0-5.92383-4-9.46777-10.68164-9.46777c-6.33008,0-10.2793,3.03711-11.24023,7.79688h-10.32812
				c0.60742-9.62012,8.80859-16.70801,21.97266-16.70801c12.91016,0,21.16211,6.83496,21.16211,17.51758v36.70606h-10.48047
				v-8.75879h-0.25195c-3.08789,5.92383-9.82227,9.66992-16.80859,9.66992C139.01125,79.5387,131.72023,73.05824,131.72023,63.48987
				z M166.09718,58.6803v-4.20215l-13.11328,0.80957c-6.53125,0.45605-10.22656,3.3418-10.22656,7.89844
				c0,4.65723,3.84766,7.69532,9.7207,7.69532C160.12257,70.88148,166.09718,65.61585,166.09718,58.6803z"/>
			<path d="M186.87648,98.22132v-8.86035c0.80859,0.20215,2.63086,0.20215,3.54297,0.20215c5.0625,0,7.79688-2.12598,9.4668-7.59375
				c0-0.10156,0.96289-3.24023,0.96289-3.29102l-19.23828-53.3125h11.8457l13.46875,43.33887h0.20117l13.46875-43.33887h11.54297
				l-19.94922,56.0459c-4.55469,12.91113-9.82031,17.0625-20.85742,17.0625
				C190.41945,98.47425,187.68507,98.37269,186.87648,98.22132z"/>
		</g>
</svg>
)

export default LogoApplePay
