import { useState, useEffect } from 'react'

export function useMedia(query: string): boolean {
  const [matches, setMatches] = useState(false)
  const [media, setMedia] = useState<MediaQueryList | null>(null)

  useEffect(() => {
    ;(async () => {
      if (typeof window === 'undefined') return
      try {
        if (!window.matchMedia) {
          await import('matchmedia-polyfill')
        }
        const media = window.matchMedia(query)
        if (!media.addEventListener) {
          await import('matchmedia-polyfill/matchMedia.addListener')
        }
        setMedia(media)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
      }
    })()
  }, [])

  useEffect(() => {
    if (!media) return
    if (media.matches !== matches) {
      setMatches(media.matches)
    }
    const listener = () => {
      setMatches(media.matches)
    }
    if (!media.addEventListener) return
    media.addEventListener('change', listener)
    return () => media.removeEventListener('change', listener)
  }, [matches, query, media])

  return matches
}
