import { createContext, useContext } from 'react'

export type Strings = Record<string, string>

const StringsContext = createContext<null | Strings>(null)

export const useStrings = () => {
  const strings = useContext(StringsContext)

  if (!strings) {
    throw new Error('useStrings: Cannot use this hook outside its context')
  }

  return strings
}

const StringsProvider = ({
  strings,
  children,
}: {
  strings: Strings
  children: React.ReactNode
}) => <StringsContext.Provider value={strings}>{children}</StringsContext.Provider>

export default StringsProvider
