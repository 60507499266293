// @ts-nocheck
/**
 * This component handles the scripts shared by every page,
 * add any new script here using the `<Script>` component.
 * Prioritization and position in the document is handled by next.js,
 * but can be controlled with the `strategy` prop, accepting the following values:
 * - `beforeInteractive` -> ASAP, injected server-side, runs before next.js scripts and runtime (critical stuff)
 * - `afterInteractive`  -> The default, runs immediately after next.js hydration (analytics)
 * - `lazyOnload`        -> Runs at idle time (external non-essential widgets)
 *
 * More info at: https://nextjs.org/docs/basic-features/script
 *
 */
import Script from 'next/script'
import { sleepUntil } from 'helpers/sleepUntil'

/**
 * Google Scripts; GTAG is used to send instrument GTM Server Side
 * please note that gtag.js works in tandem with OneTrust via the Google Consent Mode
 */

const GoogleTag = () => {
  return (
    <>
      <Script
        id={process.env.trackingScripts.googleGtag.hashedId}
        strategy='lazyOnload'
        src={process.env.trackingScripts.googleGtag.hashedSrc}
        onLoad={() => {
          // Define dataLayer and the gtag function.
          window.dataLayer = window.dataLayer || []
          function gtag() {
            dataLayer.push(arguments)
          }

          /*
           * Setup Google Consent Mode defaults (wait 500ms for OneTrust to load asynchronously and fire eventual updates to the Consent Mode) - if we dont wait, gtag.js fires twice
           * Note: the native gtag.js Region-Specific behavior is broken, because the script is proxied by Vercel
           * so, we need to update these values using OneTrust OptanonWrapper callback function (see below)
           */
          gtag('consent', 'default', {
            ad_storage: 'denied',
            analytics_storage: 'denied',
            wait_for_update: 500,
          })

          // Push default configuration to dataLayer
          dataLayer.push({
            event: 'default_consent',
          })

          // Configure the actual tag (subsequent page views are sent by next/router)
          gtag('js', new Date())
          gtag('config', 'G-XXXXXXXXXX', {
            transport_url: 'https://radar.tot.money/',
            first_party_collection: true,
          })
        }}
      />
    </>
  )
}

/**
 * This sends repeated page_view (n+1)
 * please note that page_referrer must be explicitly set null
 */

export const gtagPageView = () => {
  sleepUntil(() => typeof window.gtag !== 'undefined', 5000)
    .then(() => {
      // ready
      window.gtag('event', 'page_view', {
        page_location: document.location,
        page_title: document.title,
        page_referrer: null, // on repeated page_view (n+1) page_referrer must be explicitly set null
      })
    })
    .catch(() => {
      // timeout
    })
}

/**
 *
 * Custom Event Tracking on Frontend (gtag.js)
 *
 */

// Type definitions to ensure consistency
type GTAGEvent<EventName extends string> = {
  eventName: EventName
};

type ButtonClick = GTAGEvent<'button_click'> & {
  eventData : {
    cta : string
    destination_url : string
    context?: string
  }
}

// Tracking function
export const gtagEvent = (event: ButtonClick) => {
  if(window.tag !== 'undefined') {
    const eventBody = Object.assign(event.eventData, {page_location: window.location.href})
    window.gtag('event', event.eventName, eventBody)
  }
}

export default GoogleTag
