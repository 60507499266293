export const sleepUntil = async (condition:boolean, timeoutMs:any) => {
  return new Promise((resolve, reject) => {
    const timeWas = new Date().getTime();
    const wait = setInterval(function() {
      const timeIs = new Date().getTime();
      if (condition) {
        // Resolved
        clearInterval(wait);
        resolve(true);
      } else if ( timeIs - timeWas > timeoutMs) { 
        // Timeout
        clearInterval(wait);
        reject(new Error('sleepUntil timed out'));
      }
    }, 20);
  });
}