import styled from '@emotion/styled'
import { mq } from '@totmoney/grid'
import Container from './Container'

const Grid = styled(Container)({
  display: 'grid',
  columnGap: '1.5rem',
  gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',

  [mq.xs]: {
    gridTemplateColumns: 'repeat(12, minmax(0, 1fr))',
  },
})

export default Grid
