import styled from '@emotion/styled'
import { mq } from '@totmoney/grid'
import { lightColors } from '@totmoney/colors'
import Grid from 'components/Grid'
import { H3, Paragraph, RichText } from 'components/Typography'
import { Accordion } from '@totmoney/accordion'
import { Document } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

const FaqWrapper = styled.div({
  gridColumn: '1/-1',
  paddingBlockStart: '2.5rem',

  [mq.xs]: {
    gridColumn: '6/13',
    paddingBlockStart: '0',
  },
})

const Faq = ({
  elements,
}: {
  elements: { question: string; answer: { json: Document } | string }[]
}) => {
  return (
    <>
      <Grid>
        <H3 as='h2' css={{ gridColumnEnd: 'span 4' }}>
          Domande frequenti
        </H3>
        <FaqWrapper>
          {elements.map((el, i) => (
            <Accordion
              key={i}
              id={i}
              css={{
                borderBlockEnd: `1px solid  ${lightColors.neutral.tint}`,
                paddingBlockEnd: '1.5rem',
              }}
            >
              <Accordion.Button css={{ color: 'inherit' }}>
                <Paragraph
                  weight='bold'
                  size='l'
                  css={{ textAlign: 'left', paddingInlineEnd: '2rem' }}
                >
                  {el.question}
                </Paragraph>
              </Accordion.Button>
              <Accordion.Content>
                <RichText css={{ marginBlockStart: '1.5rem' }}>
                  {typeof el.answer === 'string' ? (
                    <p>{el.answer}</p>
                  ) : (
                    documentToReactComponents(el.answer.json)
                  )}
                </RichText>
              </Accordion.Content>
            </Accordion>
          ))}
        </FaqWrapper>
      </Grid>
    </>
  )
}

export default Faq
