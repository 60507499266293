import styled from '@emotion/styled'

import { mq } from '@totmoney/grid'
import Image from 'next/image'
import Grid from 'components/Grid'
import { ImageInfo } from './MainComponent'

import Team1 from 'public/images/team1.jpg'
import Team2 from 'public/images/team2.jpg'
import Team3 from 'public/images/team3.jpg'
import Team4 from 'public/images/team4.jpg'
import Team5 from 'public/images/team5.jpg'
import Team6 from 'public/images/team6.jpg'
import Team7 from 'public/images/team7.jpg'

const DEFAULT_IMAGES = [
  { ...Team1, url: Team1.src, description: 'foto team' },
  { ...Team2, url: Team2.src, description: 'foto team' },
  { ...Team3, url: Team3.src, description: 'foto team' },
  { ...Team4, url: Team4.src, description: 'foto team' },
  { ...Team5, url: Team5.src, description: 'foto team' },
  { ...Team6, url: Team6.src, description: 'foto team' },
  { ...Team7, url: Team7.src, description: 'foto team' },
]

const MosaicGrid = styled(Grid)({
  rowGap: '1.5rem',
  overflow: 'auto',
  gridAutoFlow: 'column',
  maxWidth: '100%',
  paddingInline: '1.5rem',
  scrollbarWidth: 'none',

  '&::-webkit-scrollbar': {
    display: 'none',
  },

  [mq.xs]: {
    gridAutoFlow: 'unset',
  },

  [mq.lg]: {
    paddingInline: '0rem',
  },
})

const ImageWrapper = styled.div({
  gridColumnEnd: 'span 12',
  position: 'relative',
  aspectRatio: '4 / 3',

  [mq.xs]: {
    borderRadius: '.5rem',
    overflow: 'hidden',
    gridColumnEnd: 'span 6',

    span: {
      minHeight: '100%',
    },
    '&:nth-of-type(3),&:nth-of-type(4),&:nth-of-type(5)': {
      gridColumnEnd: 'span 4',
    },
  },
})

const ImageMosaic = ({ images = DEFAULT_IMAGES }: { images: ImageInfo[] }) => {
  return (
    <MosaicGrid>
      {images.map((el) => (
        <ImageWrapper key={el.url}>
          <Image src={el.url} alt={el.description} fill style={{ objectFit: 'cover' }}></Image>
        </ImageWrapper>
      ))}
    </MosaicGrid>
  )
}

export default ImageMosaic
