import styled from '@emotion/styled'
import { baseColors } from '@totmoney/colors'
import { mq } from '@totmoney/grid'
import Grid from 'components/Grid'
import { H2, Paragraph } from 'components/Typography'
import availableLogos, { AvailableLogos } from 'components/logos'

const StepperTitle = styled(H2)({
  gridColumn: '1/-1',
  paddingBottom: '2.5rem',

  [mq.xs]: {
    paddingBottom: '5.5rem',
    textAlign: 'center',
  },
})

const StepperGrid = styled(Grid)({
  position: 'relative',
  overflow: 'hidden',
  rowGap: '2rem',
  gridAutoRows: '1fr',

  [mq.xs]: {
    rowGap: '1.5rem',
  },

  ':before': {
    content: '""',
    zIndex: '-1',
    height: '75%',
    width: '2px',
    position: 'absolute',
    top: 'calc(1rem - 1px)',
    left: 'calc(1rem - 1px)',
    backgroundImage: `url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 2"%3E%3Cpath fill="none" d="M1,1 L10,1" stroke-width="2" stroke="${encodeURIComponent(
      baseColors.blue[700]
    )}" stroke-linecap="round" stroke-dasharray="0 10" /%3E%3C/svg%3E');`,

    [mq.xs]: {
      backgroundImage: `url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="10" viewBox="0 0 10 2"%3E%3Cpath fill="none" d="M1,1 L10,1" stroke-width="2" stroke="${encodeURIComponent(
        baseColors.blue[700]
      )}" stroke-linecap="round" stroke-dasharray="0 10" /%3E%3C/svg%3E');`,
      height: '2px',
      width: '75%',
      top: 'calc(1.5rem - 1px)',
      left: '12.5%',
    },
  },
})

const StepWrapper = styled.div({
  gridColumnEnd: 'span 4',
  display: 'flex',
  gap: '1.5rem',

  ['p']: {
    [mq.xs]: {
      textAlign: 'center',
      maxWidth: '20ch',
    },
  },

  [mq.xs]: {
    alignItems: 'center',
    gridColumnEnd: 'span 3',
    flexDirection: 'column',
  },
})

const StepContent = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '.5rem',
  alignItems: 'flex-start',

  [mq.xs]: {
    gap: '1.5rem',
    alignItems: 'center',
  },
})

const Counter = styled.div({
  width: '2rem',
  height: '2rem',
  flex: '0 0 auto',
  lineHeight: '2rem',
  backgroundColor: baseColors.blue[700],
  margin: '0',
  borderRadius: '3rem',
  textAlign: 'center',
  color: 'white',
  fontSize: '1rem',
  fontWeight: 600,

  [mq.xs]: {
    width: '3rem',
    height: '3rem',
    lineHeight: '3rem',
    fontSize: '1.125rem',
    margin: '0 auto',
  },
})

const Stepper = ({
  title,
  elements,
}: {
  title: string
  elements: { text: React.ReactNode; image?: AvailableLogos }[]
}) => {
  return (
    <>
      <Grid>
        <StepperTitle as='h1'>{title}</StepperTitle>
      </Grid>
      <StepperGrid>
        {elements.map((el, index) => {
          const LogoImage = el.image ? availableLogos[el.image] : () => null

          return (
            <StepWrapper key={index}>
              <Counter>{index + 1}</Counter>
              <StepContent>
                <Paragraph weight='bold' css={{}}>
                  {el.text}
                </Paragraph>
                <LogoImage fill={baseColors.neutral[500]} />
              </StepContent>
            </StepWrapper>
          )
        })}
      </StepperGrid>
    </>
  )
}

export default Stepper
