import Grid from './Grid'
import { LinkInfo } from './LinkComponent'
import Stack from './Stack'
import { DisplayText, RichText } from './Typography'
import Wrapper from './Wrapper'
import BaseGradient from 'public/illustrations/gradient.jpg'
import { mq } from '@totmoney/grid'
import { LinkTrackEvent } from 'helpers/events/components/LinkTrackEvent'

const symbols = /[\r\n%#()<>?[\\\]^`{|}]/g

function encodeSVG(data: string) {
  // Use single quotes instead of double to avoid encoding.
  data = data.replace(/"/g, `'`)

  data = data.replace(/>\s{1,}</g, `><`)
  data = data.replace(/\s{2,}/g, ` `)

  // Using encodeURIComponent() as replacement function
  // allows to keep result code readable
  return data.replace(symbols, encodeURIComponent)
}

const defaultPattern = `
  <svg fill='none' xmlns='http://www.w3.org/2000/svg' width='1432' height='800' view-box='0 0 1432 800'>
    <g opacity='.2' clip-path='url(#a)' stroke='#fff' stroke-miterlimit='10'>
      <path d='M33.99 699.539c124.875 0 226.105-102.401 226.105-228.718S158.865 242.104 33.991 242.104c-124.875 0-226.105 102.4-226.105 228.717 0 126.317 101.23 228.718 226.105 228.718Z' />
      <path d='M33.99 699.539c124.875 0 226.105-102.401 226.105-228.718S158.865 242.104 33.991 242.104c-124.875 0-226.105 102.4-226.105 228.717 0 126.317 101.23 228.718 226.105 228.718Z' />
      <path d='M192.266 284.681c52.886 0 95.759-43.368 95.759-96.866 0-53.496-42.873-96.865-95.759-96.865s-95.759 43.369-95.759 96.865c0 53.498 42.873 96.866 95.759 96.866Z' />
      <path d='M192.266 284.681c52.886 0 95.759-43.368 95.759-96.866 0-53.496-42.873-96.865-95.759-96.865s-95.759 43.369-95.759 96.865c0 53.498 42.873 96.866 95.759 96.866Zm752.779 138.98c75.875 0 137.395-62.224 137.395-138.98 0-76.758-61.52-138.982-137.395-138.982-75.88 0-137.393 62.224-137.393 138.982 0 76.756 61.513 138.98 137.393 138.98Z' />
      <path d='M945.045 423.661c75.875 0 137.395-62.224 137.395-138.98 0-76.758-61.52-138.982-137.395-138.982-75.88 0-137.393 62.224-137.393 138.982 0 76.756 61.513 138.98 137.393 138.98Zm424.005 333.984c31.84 0 57.65-26.114 57.65-58.325 0-32.213-25.81-58.326-57.65-58.326-31.85 0-57.66 26.113-57.66 58.326 0 32.211 25.81 58.325 57.66 58.325Z' />
      <path d='M1369.05 757.645c31.84 0 57.65-26.114 57.65-58.325 0-32.213-25.81-58.326-57.65-58.326-31.85 0-57.66 26.113-57.66 58.326 0 32.211 25.81 58.325 57.66 58.325Z' />
      <path d='M1081.42 65.59v698.632c91.59 0 179.42-36.803 244.19-102.312 64.76-65.51 101.13-154.359 101.13-247.003 0-92.645-36.37-181.495-101.13-247.005-64.77-65.509-152.6-102.312-244.19-102.312Z' />
      <path d='M1081.42 65.59v698.632c91.59 0 179.42-36.803 244.19-102.312 64.76-65.51 101.13-154.359 101.13-247.003 0-92.645-36.37-181.495-101.13-247.005-64.77-65.509-152.6-102.312-244.19-102.312Zm-.02 358.072H808.672v275.877H1081.4V423.662Z' />
      <path d='M1081.4 423.662H808.672v275.877H1081.4V423.662ZM808.67 140.523C808.67 63.463 746.919.994 670.745.994c-76.173 0-137.924 62.47-137.924 139.529V560.01c0 77.06 61.751 139.529 137.924 139.529 76.174 0 137.925-62.469 137.925-139.529V140.523Z' />
      <path d='M808.67 140.523C808.67 63.463 746.919.994 670.745.994c-76.173 0-137.924 62.47-137.924 139.529V560.01c0 77.06 61.751 139.529 137.924 139.529 76.174 0 137.925-62.469 137.925-139.529V140.523ZM532.326 399.851c0-77.031-61.02-139.478-136.29-139.478-75.271 0-136.29 62.447-136.29 139.478v259.595c0 77.031 61.019 139.478 136.29 139.478 75.27 0 136.29-62.447 136.29-139.478V399.851Z' />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='#fff' d='M0 0h1431.06v800H0z' />
      </clipPath>
    </defs>
  </svg>
`

const GradientHero = ({
  title,
  text,
  gradient,
  pattern,
  cta,
}: {
  title: string
  text: React.ReactNode
  gradient?: string
  pattern?: string
  cta?: LinkInfo
}) => {
  return (
    <Wrapper
      css={{
        position: 'relative',
        backgroundImage: `url(${gradient || BaseGradient.src})`,
        backgroundSize: 'cover',
      }}
    >
      <div
        css={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: `url("data:image/svg+xml,${encodeSVG(pattern || defaultPattern)}")`,
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          zIndex: 1,
        }}
      ></div>
      <Grid css={{ textAlign: 'center', color: 'white', position: 'relative', zIndex: 2, gap: 0 }}>
        <Stack
          css={{
            gridColumn: '1 / 13',
            alignItems: 'center',
            [mq.xs]: {
              gridColumn: '3 / 11',
            },
          }}
        >
          <DisplayText>{title}</DisplayText>
          <RichText>{text}</RichText>
          {cta ? (
            <LinkTrackEvent ctaText={cta.label} contextText={title} href={cta.url} variant='secondary' ghost type='button'>
              {cta.label}
            </LinkTrackEvent>
          ) : null}
        </Stack>
      </Grid>
    </Wrapper>
  )
}

export default GradientHero
