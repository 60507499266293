import Image from 'next/image'
import styled from '@emotion/styled'
import { lightColors } from '@totmoney/colors'
import { mq } from '@totmoney/grid'

import Grid from 'components/Grid'
import { H2, H4, Paragraph } from 'components/Typography'
import Stack from 'components/Stack'

import MaleFace from 'public/illustrations/male-face.svg'
import MaleFace01 from 'public/illustrations/male-face-01.svg'
import FemaleFace from 'public/illustrations/female-face.svg'
import { H3 } from '@totmoney/typography'

const FACES: Record<string, any> = {
  'male-face': MaleFace,
  'male-face-01': MaleFace01,
  'female-face': FemaleFace
}

const shadowColor = '#1D1D1B14'
const IconWrapper = styled.div({
  display: 'none',

  [mq.sm]: {
    display: 'block',
    position: 'absolute',
    right: '2rem',
    width: '5rem',
    height: '5rem'
  }
})

const CardWrapper = styled.div({
  position: 'relative',
  gridColumnEnd: 'span 4',
  borderRadius: '1rem',
  borderBottomLeftRadius: '0',
  boxShadow: `0px 4px 16px 0px ${shadowColor} `,
  padding: '2.2rem 2.5rem',
  backgroundColor: lightColors.background.tint,

  [mq.xs]: {
    gridColumnEnd: 'span 6',
    padding: '3.5rem'
  }
})

const Quote = ({
  elements
}: {
  elements: {
    title: string
    text: string
    author: {
      name: string
      description: string
    }
    icon: {
      src: string
      isTop?: boolean
    }
  }[]
}) => {
  const cardGrid = {
    gridColumn: '1/-1',
    [mq.sm]: {
      ['&:nth-of-type(1)']: {
        gridColumn: '1/8',
        gridRow: '3/4'
      },

      ['&:nth-of-type(2)']: {
        gridColumn: '9/13',
        gridRow: '1/6',
        alignSelf: 'center'
      },

      ['&:nth-of-type(3)']: {
        gridColumn: '3/8',
        gridRow: '4/6'
      }
    }
  }

  return (
    <>
      <Grid
        css={{
          rowGap: '2rem',
          background:
            'url(/illustrations/pattern-quote-bullets.svg) center center no-repeat, url(/illustrations/pattern-quote.png) no-repeat center center',
          backgroundSize: 'contain'
        }}
      >
        <H2
          css={{
            gridColumn: '1/-1',
            textAlign: 'center',
            paddingBlockEnd: '4rem',
            [mq.xs]: { gridColumn: '3/11' },
            gridRow: '1/3'
          }}
        >
          Dicono di Tot
        </H2>
        {elements.map((el, i) => (
          <div css={cardGrid} key={i}>
            <CardWrapper>
              <Stack css={{ gap: '1rem', [mq.xs]: { gap: '1rem', paddingInlineEnd: '2rem' } }}>
                {el.icon ? (
                  <IconWrapper
                    css={{
                      bottom: el.icon.isTop ? 'unset' : '2rem',
                      top: el.icon.isTop ? '2rem' : 'unset'
                    }}
                  >
                    <Image src={FACES[el.icon.src]} fill alt='' />
                  </IconWrapper>
                ) : null}
                <H4 as='h3'>{el.title}</H4>
                <Paragraph>{el.text}</Paragraph>
                <div>
                  <Paragraph weight='bold'>{el.author.name}</Paragraph>
                  <Paragraph css={lightColors.neutral.base}>{el.author.description}</Paragraph>
                </div>
              </Stack>
            </CardWrapper>
          </div>
        ))}
      </Grid>
    </>
  )
}

export default Quote
