// @ts-nocheck
/**
 * This component handles the scripts shared by every page,
 * add any new script here using the `<Script>` component.
 * Prioritization and position in the document is handled by next.js,
 * but can be controlled with the `strategy` prop, accepting the following values:
 * - `beforeInteractive` -> ASAP, injected server-side, runs before next.js scripts and runtime (critical stuff)
 * - `afterInteractive`  -> The default, runs immediately after next.js hydration (analytics)
 * - `lazyOnload`        -> Runs at idle time (external non-essential widgets)
 *
 * More info at: https://nextjs.org/docs/basic-features/script
 *
 */
import Script from 'next/script'
import { sleepUntil } from 'helpers/sleepUntil'

/*
 *  OneTrust Cookies Consent and Related Scripts
 */
const OneTrustCookieConsent = () => (
  <>
    <Script
      id={process.env.trackingScripts.oneTrustCookie.hashedId}
      strategy='afterInteractive'
      src={process.env.trackingScripts.oneTrustCookie.hashedSrc}
      type='text/javascript'
      data-domain-script={
        process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
          ? 'e6be5b64-0d07-4ed1-abce-524c869be08d'
          : 'e6be5b64-0d07-4ed1-abce-524c869be08d-test'
      }
      onLoad={() => {
        /*
         * OptanonWrapper() is the callback function, called by OneTrust after consent is set or updated
         *
         * Note 1: To keep code clean, and scripts asyncronous, we generate a custom JS event to notify next/script loaded scripts to load or reload
         * Note 2: We need to use OptanonWrapper to update the Google Consent Mode (GCM) defaults for the geography, after OneTrust has loaded
         *         this is a workaround, because OneTrust SDK currently does not update the GCM defaults upon loading (it only updates them on consent update)
         */

        window.OptanonWrapper = function () {
          // gtag.js / Consent Mode / OneTrust workaround 1 of 2
          if (
            window.OptanonActiveGroups.includes(
              OneTrust.GetDomainData().GoogleConsent.GCAdStorage
            ) == true
          ) {
            gtag('consent', 'update', {
              ad_storage: 'granted',
            })
          }

          // gtag.js / Consent Mode / OneTrust workaround 2 of 2
          if (
            window.OptanonActiveGroups.includes(
              OneTrust.GetDomainData().GoogleConsent.GCAnalyticsStorage
            ) == true
          ) {
            gtag('consent', 'update', {
              analytics_storage: 'granted',
            })
          }
        }
      }}
    />
  </>
)

/**
 * This opens the cookie banner options 
 * Banner SDK JavaScript API:  https://my.onetrust.com/s/article/UUID-d8291f61-aa31-813a-ef16-3f6dec73d643?topicId=0TO1Q000000ssJBWAY
 */

export const oneTrustOpen = () => {
  sleepUntil(() => typeof window.OneTrust !== 'undefined', 5000)
    .then(() => {
      // ready
      OneTrust.ToggleInfoDisplay();
    })
    .catch(() => {
      // timeout
    })
}

export default OneTrustCookieConsent