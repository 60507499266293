import styled from '@emotion/styled'
import { lightColors } from '@totmoney/colors'
import { mq } from '@totmoney/grid'
import { Tabs } from '@totmoney/tabs'
import { DisplayText, Paragraph } from 'components/Typography'
import { FC, ReactNode, useCallback, useEffect, useState } from 'react'
import PricingCard, { PricingCardProps } from './PricingCard'
import Stack from './Stack'
import { spaces } from '@totmoney/spaces'
import Container from './Container'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InfiniteLoopSlider } from './InfiniteLoopSlider'
import { useMediaQuery } from 'helpers/hooks/useMediaQuery'
import { pipe } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import { useRouter } from 'next/router'

const CardWrapper = styled.div<{ cardQty: number }>`
  display: grid;
  grid-template-columns: 12fr;
  justify-items: center;

  gap: ${spaces.m};
  margin: 0 auto;
  ${mq.md} {
    margin: 0 ${spaces.xl};
    grid-template-columns: ${({ cardQty }) => (cardQty === 1 ? '1fr' : '4fr 4fr 4fr')};

    & > [data-for='plan-card']:not(:has([data-for='plan-card-label'])) {
      margin-top: 3.5rem;
    }

    & > [data-for='plan-card'] {
      max-width: ${({ cardQty }) => (cardQty === 1 ? '32rem' : 'none')};
      min-width: 18.4rem;
    }
  }
`

const PricingContainer = styled(Container)<{ isPricingPage: boolean }>`
  display: grid;
  grid-template-rows: ${({ isPricingPage }) => `repeat(${isPricingPage ? 4 : 3}, auto);`};
  gap: ${({ isPricingPage }) => (isPricingPage ? spaces.xl : spaces.xxl)};

  ${mq.lg} {
    max-width: 64rem;
  }
  ${mq.xl} {
    max-width: 75rem;
  }
`

const TabsContainer = styled.div`
  display: flex;
  justify-content: center;
`

const BannerContainer = styled.div`
  display: flex;
  width: fit-content;
  gap: ${spaces.xl};

  ${mq.sm} {
    justify-content: center;
    width: 100%;
  }
`
const BannerItemContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => (theme.colors ?? lightColors).tertiary.base};
  gap: ${spaces.xxs};
`

const StyledIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => (theme.colors ?? lightColors).accent.base};
`
const StyledParagraph = styled(Paragraph)`
  white-space: nowrap;
`
type BannerItemProps = {
  content?: string
}
const BannerItem: FC<BannerItemProps> = ({ content }) => (
  <BannerItemContainer>
    <StyledIcon icon={faCircleCheck} fontSize={24} />

    <StyledParagraph>{content}</StyledParagraph>
  </BannerItemContainer>
)

const PricingPlans = ({
  title,
  text,
  bannerText,
  elements
}: {
  title?: string
  text?: ReactNode
  bannerText?: string[]
  elements: {
    name: string
    card: PricingCardProps[]
  }[]
}) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const isMediumScreen = useMediaQuery('sm')
  const router = useRouter()
  const isPricingPage = router.asPath.includes('/prezzi')

  const handleActive = useCallback((index: number) => {
    setCurrentIndex(index)
  }, [])

  const showTabs = elements.some((item) => typeof item.name === 'string' && item.name.trim() !== '')

  const yearlyIndex = pipe(
    elements,
    A.findIndex((c) => c.name === 'Annuale'),
    O.getOrElse(() => 0)
  )

  // Wait until after client-side hydration to show
  useEffect(() => {
    if (showTabs) {
      handleActive(yearlyIndex)
    }
  }, [isPricingPage, showTabs])

  return (
    <PricingContainer isPricingPage={isPricingPage}>
      {title || text ? (
        <Stack
          css={{
            textAlign: 'center'
          }}
        >
          {title ? <DisplayText as='h1'>{title}</DisplayText> : null}
          {text ? <Paragraph>{text}</Paragraph> : null}
        </Stack>
      ) : null}
      {bannerText ? (
        isMediumScreen ? (
          <BannerContainer>
            {bannerText.map((bannerItem, i) => (
              <BannerItem key={`banner-text-${i}`} content={bannerItem} />
            ))}
          </BannerContainer>
        ) : (
          <InfiniteLoopSlider>
            <BannerContainer>
              {bannerText.map((bannerItem, i) => (
                <BannerItem key={`banner-text-${i}`} content={bannerItem} />
              ))}
            </BannerContainer>
          </InfiniteLoopSlider>
        )
      ) : null}
      {showTabs && elements ? (
        <TabsContainer>
          <Tabs variant='secondary' activeIndex={yearlyIndex}>
            {elements.map((item, i) => (
              <button type='button' key={`tab-${i}`} onClick={() => handleActive(i)}>
                {item.name}
              </button>
            ))}
          </Tabs>
        </TabsContainer>
      ) : null}

      {elements.map(
        (item, i) =>
          i === currentIndex && (
            <CardWrapper key={`opt-${i}`} cardQty={item.card.length}>
              {item.card.map((el, j) => (
                <PricingCard key={j} {...el} isPricingPage={isPricingPage} />
              ))}
            </CardWrapper>
          )
      )}
    </PricingContainer>
  )
}

export default PricingPlans
