import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import Card from 'components/Card'
import Faq from 'components/Faq'
import Hero from 'components/Hero'
import MainComponent from 'components/MainComponent'
import PricingPlans from 'components/PricingPlans'
import QuadCard from 'components/QuadCard'
import Quote from 'components/Quote'
import Stepper from 'components/Stepper'
import Wrapper from 'components/Wrapper'
import PreFooter from 'components/PreFooter'
import LongHero from 'components/LongHero'
import {
  ComponentFaq,
  ComponentHero,
  ComponentMain,
  ComponentQuadCard,
  LandingPagePageElementsItem
} from './api/schema'
import TableAccordion from 'components/TableAccordion'
import { AvailableLogos } from 'components/logos'
import GradientHero from 'components/GradientHero'
import ImageMosaic from 'components/ImageMosaic'
import LogoSlider from 'components/LogoSlider'
import { Fragment } from 'react'

/**
 * Components not directly managed on Contentful (typed as ComponentJson)
 * are remapped here to their respective JSX component
 */
const COMPONENT_MAPPINGS: Record<string, React.ComponentType<any>> = {
  Stepper: Stepper,
  Pricing: PricingPlans,
  Card: Card,
  Quote: Quote,
  PreFooter: PreFooter,
  TableAccordion: TableAccordion,
  LongHero: LongHero,
  GradientHero: GradientHero,
  ImageMosaic: ImageMosaic,
  LogoSlider: LogoSlider
}

// The check functions are a little verbose, but they ensure correct typing.
// If you're adding the check for a new component, don't forget to graphql:update
// to get the updated types from the Contentful API
const isHero = (component: LandingPagePageElementsItem): component is ComponentHero =>
  component.__typename === 'ComponentHero'
const isMain = (component: LandingPagePageElementsItem): component is ComponentMain =>
  component.__typename === 'ComponentMain'
const isQuadCard = (component: LandingPagePageElementsItem): component is ComponentQuadCard =>
  component.__typename === 'ComponentQuadCard'
export const isFaq = (component: LandingPagePageElementsItem): component is ComponentFaq =>
  component.__typename === 'ComponentFaq'

/**
 * This is the main builder function: it checks the type of the passed component using the
 * above functions and then builds the JSX with the correct props. To add a new component,
 * just add the check function above, the check itself below and in it the JSX element.
 *
 * If you need to add a json-only component just add it to the above mappings,
 * but be sure that the props from the json have the same names of the component's (see [1]).
 * @param el The component data from Contentful
 * @returns The built JSX
 */
const getMappedComponent = (el: LandingPagePageElementsItem) => {
  if (isHero(el)) {
    return (
      <Hero
        name={el.name}
        text={documentToReactComponents(el.description.json)}
        title={el.title}
        image={el.image}
        links={[el.link1, el.link2]}
      />
    )
  }

  if (isMain(el)) {
    return (
      <MainComponent
        name={el.name}
        subtitle={el.subtitle}
        title={el.title}
        text={documentToReactComponents(el.text.json)}
        imageFirst={el.imageFirst}
        hideImageOnMobile={el.hideImageOnMobile}
        links={[el.link1, el.link2]}
        image={el.image}
        logos={el.svgLogos as AvailableLogos[]}
        linksAsButtons={el.background === 'dark'}
      />
    )
  }

  if (isQuadCard(el)) {
    return <QuadCard elements={el.cardListCollection.items} />
  }

  if (isFaq(el)) {
    return <Faq elements={el.faqListCollection.items} />
  }

  // At this point, the component should be one of those managed as json on Contentful
  // If it isn't, then a component is not handled properly. If it is we check
  // that the data exists and has a relative mapping.
  // TOFIX: Should these be warnings? Or should they break the build?
  if (el.__typename !== 'ComponentJson') {
    throw Error('Unhandled component from Contentful')
  }
  //
  if (
    !el.componentData.component ||
    typeof el.componentData.component !== 'string' ||
    !COMPONENT_MAPPINGS[el.componentData.component]
  ) {
    throw Error(
      `Wrong data format on ComponentJson from Contentful. The JSON must contain a component prop and the value should be one of: ${Object.keys(
        COMPONENT_MAPPINGS
      ).join(' ')}. Received ${el.componentData.component}`
    )
  }

  const MappedComponent = COMPONENT_MAPPINGS[el.componentData.component]
  // [1] Note that the props are spreaded over so the json from Contentful
  // should match the actual JSX props 1:1, there's no way to typecheck that here
  return <MappedComponent {...el.componentData.data} />
}

/**
 * Builds the JSX for a list of components data from Contenful.
 * The function itself is just a map and some code to manage the <Wrapper />,
 * the logic is handled in the getMappedComponet function.
 * @param components The components of the page
 * @returns The built JSX
 */
const buildPage = (components: LandingPagePageElementsItem[]) => {
  return components.map((el, i) => {
    // Hero wrapper requires special paddings
    const spacingDefaults = isHero(el) ? [1, 1, 2, 0] : [2, 3, 2, 3]

    // GradientHero requires no wrapper
    return el.__typename === 'ComponentJson' && el.componentData.component === 'GradientHero' ? (
      <Fragment key={el.sys.id}>{getMappedComponent(el)}</Fragment>
    ) : (
      <Wrapper
        paddingTop={[el.paddingTopMobile || spacingDefaults[0], el.paddingBottomDesktop || spacingDefaults[1]]}
        paddingBottom={[el.paddingBottomMobile || spacingDefaults[2], el.paddingBottomDesktop || spacingDefaults[3]]}
        theme={el.background}
        key={el.sys.id}
      >
        {getMappedComponent(el)}
      </Wrapper>
    )
  })
}

export default buildPage
