import styled, { CSSObject } from '@emotion/styled'

import Grid from 'components/Grid'
import { DisplayText, RichText } from 'components/Typography'

import { LinkInfo } from 'components/LinkComponent'
import { mq, breakpoints } from '@totmoney/grid'
import Stack from './Stack'
import { ImageInfo } from './MainComponent'
import { LinkTrackEvent } from 'helpers/events/components/LinkTrackEvent'

const heroStyles: CSSObject = {
  position: 'relative',

  [mq.xs]: {
    minHeight: '33.75rem',
  },
}

const TextWrapper = styled(Stack)({
  gridColumn: '1 / 7',
  alignSelf: 'center',
  maxWidth: '100%',
  // maxHeight: '100vh',

  // [mq.xs]: {
  //   maxHeight: 'none',
  // },
})

const ButtonWrapper = styled.div({
  display: 'flex',
  alignItems: 'stretch',
  flexDirection: 'column',
  gap: '1rem',

  [mq.xs]: {
    alignItems: 'flex-start',
    flexDirection: 'row',
    gap: '0.5rem',
    textAlign: 'center',
  },
})

const getColumnPosition = (column: number, end?: boolean) => {
  return end
    ? `calc(((100% - (1.5rem * 11))/ 12 * ${column}) + (1.5rem * ${column - 1}))`
    : `calc(((100% - (1.5rem * 11))/ 12 * ${column - 1}) + (1.5rem * ${column - 1}))`
}

const ImageWrapper = styled.div({
  width: '100vw',
  marginLeft: '-1.5rem',

  [mq.xs]: {
    position: 'absolute',
    bottom: 0,
    left: getColumnPosition(7, true),
    height: '33.75rem',
    marginLeft: 0,
    width: 'calc(50vw - 5.625rem)',
  },

  ['picture, img']: {
    display: 'block',
    objectFit: 'contain',
    objectPosition: 'bottom left',
    width: '100%',
    height: '100%',

    [mq.xs]: {
      width: 'auto',
    },
  },
})

const getSourceSetDesktop = (url: string) => {
  return `${url}?w=1024&q=75&fm=webp, ${url}?w=2048&q=75&fm=webp 2x`
}

const StyledLinkTrackEvent = styled(LinkTrackEvent)({ margin: 0 })

// The hero mobile image will be at least ${breakpoints.xs}px wide,
// and tall 3/4 of its width. Once we have these dimensions we can
// get all the sizes we want just by expanding the list below.
const mobileImageSizes = [2, 1.5, 1, 0.75, 0.5, 0.25]

const getSourceSetMobile = (url: string) => {
  const srcs = mobileImageSizes.map(
    (multiplier) =>
      // The image should be cropped on the left, so f=left and fit=fill are required here
      `${url}?f=left&fit=fill&w=${breakpoints.xs * multiplier}&h=${
        (breakpoints.xs / 4) * 3 * multiplier
      }&q=75&fm=webp ${breakpoints.xs * multiplier}w`
  )

  return srcs.join(', ')
}

const Hero = ({
  title,
  text,
  links,
  image,
  name
}: {
  title: string
  text: React.ReactNode
  links?: LinkInfo[]
  image: ImageInfo
  name: string
}) => {
  return (
    <Grid css={heroStyles}>
      <TextWrapper>
        <DisplayText as='h1'>{title}</DisplayText>
        <ImageWrapper>
          <picture>
            <source
              srcSet={getSourceSetDesktop(image.url)}
              media={`(min-width: ${breakpoints.xs}px)`}
            />
            <img
              alt={image.description}
              sizes='100vw'
              srcSet={getSourceSetMobile(image.url)}
              src={`${image.url}?f=left&fit=fill&w=${breakpoints.xs}&h=${
                (breakpoints.xs / 4) * 3
              }&q=75&fm=webp`}
              width={breakpoints.xs}
              height={(breakpoints.xs / 4) * 3}
              decoding='async'
            />
          </picture>
        </ImageWrapper>
        <RichText>{text}</RichText>
        <ButtonWrapper>
          {/* TODO: Centralizzare annullamento margini? */}
          {links && links[0] ? (
            <StyledLinkTrackEvent type='button' contextText={name} ctaText={links[0].label} href={links[0].url}>{links[0].label}</StyledLinkTrackEvent>
          ) : null}
          {links && links[1] ? <LinkTrackEvent {...links[1]} type='linkWithIcon' asGhostButton contextText={name} ctaText={links[1].label} icon='link' href={links[1].url} ></LinkTrackEvent> : null}
        </ButtonWrapper>
      </TextWrapper>
    </Grid>
  )
}

export default Hero
