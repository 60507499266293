import { LinkButton, LinkButtonProps } from 'components/LinkButton'
import LinkComponent, { LinkInfo } from 'components/LinkComponent'
import { NavLink } from 'components/NavLink'
import { gtagEvent } from 'components/scripts/GoogleTag'
import Link, { LinkProps } from 'next/link'

type LinkAsButton = {
  type: 'button'
  onClick?: React.MouseEventHandler<HTMLButtonElement>
} & LinkButtonProps
type LinkNext = { type: 'link', onClick?: React.MouseEventHandler<HTMLAnchorElement> } & LinkProps & React.AnchorHTMLAttributes<HTMLAnchorElement>
type linkWithIcon = {
  type: 'linkWithIcon'
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
} & Omit<LinkInfo, 'url'>
type LinkNav = {
  type: 'navLink'
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
} & LinkProps

export type BaseProps = { href: string; ctaText?: string; contextText?: string }
export type LinkTrackEventProps = React.PropsWithChildren<BaseProps & (LinkAsButton | LinkNext | linkWithIcon | LinkNav)>

/**
 * @param href URL to navigate to
 * @param ctaText button/link text
 * @param contextText where the link or buttons are located, usually matches to the section **Title** of the page
 */
export const LinkTrackEvent: React.FC<LinkTrackEventProps> = ({href, contextText, ctaText, children, ...props}) => {
  function trackEvent<T>(e: React.MouseEvent<T>, href: string, onClick?: React.MouseEventHandler<T>) {
    gtagEvent({
      eventName: 'button_click',
      eventData: {
        cta: ctaText ? ctaText : '',
        destination_url: href,
        context: contextText === '' ? 'MISSING NAME ON CONTENTENFUL' : contextText,
      },
    })
    if (onClick) {
      onClick(e)
    }
  }

  switch (props.type) {
    case 'button':
      return (
        <LinkButton {...props} href={href} onClick={(e) => trackEvent(e, href, props.onClick)} variant={props.variant}>
          {children}
        </LinkButton>
      )
    case 'linkWithIcon':
      return (
        <LinkComponent
          {...props}
          url={href}
          onClick={(e) => trackEvent(e, href, props.onClick)}
          label={props.label}
          adaptable={props.adaptable}
          asGhostButton={props.asGhostButton}
          icon={props.icon}
        ></LinkComponent>
      )
    case 'link':
      return <Link {...props} onClick={(e) => trackEvent(e, href, props.onClick)} href={href}>{children}</Link>
    case 'navLink':
      return <NavLink {...props} onClick={(e) => trackEvent(e, href, props.onClick)} href={href}>{children}</NavLink>
  }
}
