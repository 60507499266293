import styled, { CSSObject } from '@emotion/styled'
import Link, { LinkProps } from 'next/link'
import { lightColors } from '@totmoney/colors'

// TODO: Move to _app.tsx
//disable font awesome autoAddCss to avoid huge icon flash on itial page load
import { config } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'
config.autoAddCss = false

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { faArrowDownToBracket } from 'data/icons/fa-arrow-down-to-bracket-regular'

const LinkLabel = styled(Link)<{ adaptable?: boolean }>(({ adaptable }) => ({
  color: adaptable ? `var(--accent, ${lightColors.accent.base})` : lightColors.accent.base,
  fontWeight: 600
}))

const LinkIcon = styled(FontAwesomeIcon)({
  color: 'inherit',
  cursor: 'pointer',
  marginLeft: '0.5rem'
})

const buttonStyles: CSSObject = {
  margin: '0.75rem 2rem',
  lineHeight: 1.5,
  display: 'inline-block',
  textAlign: 'center'
}

export type LinkInfo = {
  url: string
  label?: string
  icon?: keyof typeof ICON_MAPPINGS
  asGhostButton?: boolean
  adaptable?: boolean
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
} & Omit<LinkProps, 'href'>

const ICON_MAPPINGS = {
  link: faArrowRight,
  chevron: faChevronRight,
  download: faArrowDownToBracket
} as const

const LinkComponent = ({ url, label, icon, asGhostButton, adaptable, onClick }: LinkInfo) => {
  return (
    <LinkLabel onClick={onClick} href={url} css={asGhostButton ? buttonStyles : undefined} adaptable={adaptable}>
      {label}
      {icon ? <LinkIcon icon={ICON_MAPPINGS[icon]} /> : null}
    </LinkLabel>
  )
}

export default LinkComponent
