import styled from '@emotion/styled'
import { mq } from '@totmoney/grid'
import { lightColors } from '@totmoney/colors'
import Grid from 'components/Grid'
import { Label, Paragraph } from 'components/Typography'
import { Accordion } from '@totmoney/accordion'

const TableWrapper = styled.div({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  paddingBlock: '1rem',
  gap: '1rem',
  borderBlockStart: `1px solid  ${lightColors.neutral.tint}`,

  [mq.xs]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '2rem',
  },
})

const TableAccordion = ({
  elements,
}: {
  elements: {
    question: string
    answer: {
      title?: string
      description: React.ReactNode
      price?: string
      priceDescription?: string
    }[]
  }[]
}) => {
  return (
    <>
      <Grid>
        <div css={{ gridColumn: '1/-1', display: 'flex', flexDirection: 'column', gap: '1.5rem' }}>
          {elements.map((el, i) => (
            <Accordion css={{ marginBlockEnd: '0' }} key={i}>
              <Accordion.Button>
                <Label as='p' size='m' css={{ textAlign: 'left' }}>
                  {el.question}
                </Label>
              </Accordion.Button>
              <Accordion.Content>
                {el.answer ? (
                  <>
                    {el.answer.map((item, i) => (
                      <TableWrapper key={i}>
                        <div css={{ maxWidth: '43rem' }}>
                          <Label as='p' size='m'>
                            {item.title}
                          </Label>
                          <Label as='p' size='s'>
                            {item.description}
                          </Label>
                        </div>
                        <div css={{ [mq.xs]: { textAlign: 'right' } }}>
                          {item.price ? (
                            <Label as='p' size='m'>
                              {item.price}
                            </Label>
                          ) : null}
                          <Label as='p' size='s'>
                            {item.priceDescription}
                          </Label>
                        </div>
                      </TableWrapper>
                    ))}
                  </>
                ) : null}
              </Accordion.Content>
            </Accordion>
          ))}
        </div>
      </Grid>
    </>
  )
}

export default TableAccordion
