import styled, { CSSObject } from '@emotion/styled'
import isPropValid from '@emotion/is-prop-valid'
import { lightColors } from '@totmoney/colors'
import { mq } from '@totmoney/grid'

/**
 * Utils
 */

const emCalc = (pixels: number, base = 16) => pixels / base

const remCalc = (pixels: number) => {
  if (pixels > 2) {
    return `${pixels / 16}rem`
  }
  return `${pixels}px`
}

const calcSize = (min: number, max: number, minWidthPx = 375, maxWidthPx = 1023) => {
  const round = (val: number) => Math.round(val * 1000000) / 1000000
  const pixelsPerRem = 16

  const minWidth = minWidthPx / pixelsPerRem
  const maxWidth = maxWidthPx / pixelsPerRem

  const minSize = min / pixelsPerRem
  const maxSize = max / pixelsPerRem

  const slope = (maxSize - minSize) / (maxWidth - minWidth)
  const yAxisIntersection = -minWidth * slope + minSize
  const vwSlope = slope * 100

  const minDimension = min > max ? maxSize : minSize
  const maxDimension = min > max ? minSize : maxSize

  // clamp is better but is not supported by older Safari versions (< 13). For now let's rely on a double min max implementation
  // return `clamp( ${minDimension}rem, ${yAxisIntersection}rem + ${slope * 100}vw, ${maxDimension}rem )`
  return `max(${minDimension}rem, min(${round(yAxisIntersection)}rem + ${round(vwSlope)}vw, ${maxDimension}rem))`
}

export const gradientTextStyle = {
  display: 'inline-block',
  // TODO: Use colors from @totmoney/colors
  background: 'linear-gradient(90deg, #408DF9 0%, #9184D3 27.08%, #D5AAF2 52.6%, #DC648A 75.52%, #FF4654 100%);',
  color: 'transparent',
  WebkitBackgroundClip: 'text',
  backgroundClip: 'text'
}

/**
 * Headings
 */

export const displayTextStyle = {
  fontSize: calcSize(40, 56),
  lineHeight: calcSize(46, 64),
  fontWeight: 700,
  letterSpacing: '-1px',
  margin: 0,

  '[data-theme="dark"] &': gradientTextStyle
}

export const DisplayText = styled.span(displayTextStyle)

export const h1Style = {
  fontSize: calcSize(36, 48),
  lineHeight: calcSize(42, 56),
  fontWeight: 700,
  letterSpacing: '-1px',
  margin: 0,

  '[data-theme="dark"] &': gradientTextStyle
}

export const H1 = styled.h1(h1Style)

export const h2Style = {
  fontSize: calcSize(32, 40),
  lineHeight: calcSize(40, 48),
  fontWeight: 700,
  letterSpacing: '-1px',
  margin: 0,

  '[data-theme="dark"] &': gradientTextStyle
}

export const H2 = styled.h2(h2Style)

export const h3Style = {
  fontSize: calcSize(28, 32),
  lineHeight: calcSize(34, 40),
  fontWeight: 700,
  letterSpacing: '-1px',
  margin: 0,

  '[data-theme="dark"] &': gradientTextStyle
}

export const H3 = styled.h3(h3Style)

export const h4Style = {
  fontSize: calcSize(24, 24),
  lineHeight: calcSize(32, 32),
  fontWeight: 700,
  margin: 0,

  '[data-theme="dark"] &': gradientTextStyle
}

export const H4 = styled.h4(h4Style)

/**
 * Paragraphs & labels
 */

export const paragraphStyle = {
  fontSize: '1rem',
  lineHeight: '1.625rem',
  fontWeight: 400,
  margin: 0,

  'b, strong': {
    fontWeight: 600
  },

  '[data-theme="dark"] &': {
    color: 'white'
  }
}

export const largeParagraphStyle = {
  fontSize: '1.125rem',
  lineHeight: '2rem',
  fontWeight: 400,
  margin: 0,

  'b, strong': {
    fontWeight: 600
  },

  '[data-theme="dark"] &': {
    color: 'white'
  }
}

export const Paragraph = styled('p', {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== 'size' && prop !== 'weight'
})<{ size?: 'm' | 'l'; weight?: 'normal' | 'bold' }>((props) => ({
  ...(props.size === 'l' ? largeParagraphStyle : paragraphStyle),
  fontWeight: props.weight === 'bold' ? 600 : 400
}))

export const extraSmallLabelStyle = {
  fontSize: '0.75rem',
  lineHeight: '1.5rem',
  fontWeight: 400,
  margin: 0,

  '[data-theme="dark"] &': {
    color: 'white'
  }
}

export const smallLabelStyle = {
  fontSize: '0.875rem',
  lineHeight: '1.5rem',
  fontWeight: 400,
  margin: 0,

  '[data-theme="dark"] &': {
    color: 'white'
  }
}

export const mediumLabelStyle = {
  fontSize: '0.875rem',
  lineHeight: '1.25rem',
  fontWeight: 600,
  margin: 0,

  '[data-theme="dark"] &': {
    color: 'white'
  }
}

export const Label = styled('span', {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== 'size'
})<{ size: 'xs' | 's' | 'm' }>`
  ${({ size }) => {
    switch (size) {
      case 'xs':
        return extraSmallLabelStyle
      case 'm':
        return mediumLabelStyle
      case 's':
        return smallLabelStyle
      default:
        return mediumLabelStyle
    }
  }}
`

export const captionStyle = {
  fontSize: '0.75rem',
  lineHeight: '1rem',
  fontWeight: 400,
  margin: 0
}

export const Caption = styled.span(captionStyle)

// This is the style used for unstyled rich text
export const richText: CSSObject = {
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',

  p: paragraphStyle,

  ul: {
    margin: 0,
    paddingLeft: '1.5rem',
    '[data-theme="dark"] &': {
      color: 'white'
    }
  }
}

export const RichText = styled.div(richText)

// This is the style used for unstyled rich text on blog posts
export const editorialText: CSSObject = {
  h2: h3Style,

  h3: h4Style,

  h4: h4Style,

  'h1, h2, h3, h4': {
    padding: '1.5rem 0 0'
  },

  'p, a, li, cite, i, figcaption': {
    ...largeParagraphStyle,
    padding: 0,
    lineHeight: 1.555556
  },

  strong: {
    fontWeight: '600'
  },

  del: {
    textDecoration: 'line-through'
  },

  span: {
    color: lightColors.accent.base,
    lineHeight: 2,
    backgroundColor: lightColors.background.base,
    padding: '6px 10px',
    borderRadius: '0.5rem',
    border: `1px solid ${lightColors.neutral.base}`
  },

  code: {
    color: lightColors.negative.shade,
    lineHeight: 2,
    backgroundColor: lightColors.background.base,
    padding: '6px 10px',
    borderRadius: '0.5rem',
    border: `1px solid ${lightColors.neutral.base}`
  },

  a: {
    fontWeight: '600',
    color: lightColors.accent.base,

    '&:hover': {
      color: lightColors.accent.highest
    }
  },

  'ol:not([class])': {
    paddingLeft: '1.2rem',
    fontSize: '1rem',
    lineHeight: '1.5rem',
    paddingTop: '0.4375rem',
    paddingBottom: '1.0625rem',
    marginTop: 0,
    marginBottom: 0,
    fontWeight: '500',

    li: {
      listStyle: 'normal'
    }
  },

  figure: {
    margin: 0,

    img: {
      aspectRatio: '16 / 9',
      width: ' 100%',
      objectFit: 'cover',

      '@supports not (aspect-ratio: 16/9)': {
        maxHeight: '25rem'
      }
    },

    figcaption: {
      ...smallLabelStyle,
      paddingBlock: '1rem 0rem'
    }
  },

  blockquote: {
    position: 'relative',
    paddingBlock: '1.5rem',
    paddingLeft: '3.2rem',
    margin: 0,

    [mq.lg]: {
      maxWidth: '80%',
      margin: 'auto'
    },

    p: {
      ...h3Style,
      paddingBlock: 0,
      lineHeight: 1.2,
      color: lightColors.tertiary.base,

      '&:before': {
        ...h1Style,
        padding: 0,
        content: '"“"',
        position: 'absolute',
        left: 0,
        top: 0,
        color: lightColors.accent.base
      }
    },

    'cite, i': {
      fontStyle: 'normal',
      fontWeight: 600,
      paddingTop: '0.75rem'
    },

    i: {
      color: lightColors.neutral.shade
    }
  }
}
