import LogoApplePay from './LogoApplePay'
import LogoContactless from './LogoContactless'
import LogoGooglePay from './LogoGooglePay'
import LogoSella from './LogoSella'
import LogoTot from './LogoTot'
import LogoVisa from './LogoVisa'
import LogoFidoCommercialista from './LogoFidoCommercialista'

const availableLogos = {
  sella: LogoSella,
  visa: LogoVisa,
  'google-pay': LogoGooglePay,
  googlepay: LogoGooglePay,
  contactless: LogoContactless,
  tot: LogoTot,
  'apple-pay': LogoApplePay,
  applepay: LogoApplePay,
  fidocommercialista: LogoFidoCommercialista,
}

export type AvailableLogos = keyof typeof availableLogos

export default availableLogos
