import styled from '@emotion/styled'
import { mq } from '@totmoney/grid'

const Container = styled.div({
  margin: '0 auto',
  maxWidth: 'calc(100% - 3rem)',

  [mq.lg]: {
    maxWidth: '60rem',
  },

  [mq.xl]: {
    maxWidth: '75rem',
  },
})

export default Container
