import { lightColors } from '@totmoney/colors'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { mq } from '@totmoney/grid'
import Link, { LinkProps } from 'next/link'
import { css } from '@emotion/react'

export const NavLinkStyle = css`
  appearance: none;
  cursor: pointer;
  border: none;
  background-color: transparent;
  white-space: nowrap;
  display: flex;
  width: 100%;
  gap: 1.5rem;
  padding: 1rem 0;
  line-height: 1.5;

  ${mq.lg} {
    padding: 0;
    width: auto;
    display: inline-flex;
    align-items: center;

    &:hover {
      color: ${lightColors.accent.base};
    }
  }
`

export type NavLinkProp = React.PropsWithChildren<LinkProps>
export const NavLink: React.FC<NavLinkProp> = ({ children, ...props }) => (
  <Link {...props} css={NavLinkStyle} >
    {children}
  </Link>
)
