import { PlanCard } from '@totmoney/plancard'
import { useRouter } from 'next/router'
import { gtagEvent } from './scripts/GoogleTag'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { BadgeVariant } from '@totmoney/badge'
import { useMediaQuery } from 'helpers/hooks/useMediaQuery'
import { FC } from 'react'

export const StyledUl = styled.ul`
  align-self: baseline;
  height: fit-content;
`

export const resetPadding = css`
  padding: 0;
`

type Feature = {
  description: string
  tooltip?: string
}
type FeatureSection = {
  features: ReadonlyArray<Feature>
  title?: string
  type?: BadgeVariant
  showIcon?: boolean
}

type PlanLabel = {
  colors: ReadonlyArray<string>
  text: string
  icon?: boolean
}
export type PricingCardProps = {
  readonly planId: string
  readonly planTitle: string
  readonly planDescription: string
  readonly price: number
  readonly billingFrequencyText: string
  readonly ctaText: string
  readonly isActive: boolean
  readonly defaultList: FeatureSection
  readonly newsList?: FeatureSection
  readonly comingSoonList?: FeatureSection
  readonly billingFrequencyDiscountText?: string
  readonly cardLink?: string
  readonly planLabels?: PlanLabel
  readonly discountBadge?: string
  readonly discountDetailsText?: string
}

interface PricingPageProps extends PricingCardProps {
  isPricingPage: boolean
}

const PricingCard: FC<PricingPageProps> = ({
  planTitle,
  planDescription,
  price,
  billingFrequencyText,
  ctaText,
  isActive,
  defaultList,
  newsList,
  comingSoonList,
  billingFrequencyDiscountText,
  cardLink,
  planLabels,
  discountBadge,
  discountDetailsText,
  isPricingPage
}) => {
  const router = useRouter()
  const isMediumScreen = useMediaQuery('md')

  const featureSections: FeatureSection[] = [
    defaultList,
    ...(newsList ? [newsList] : []),
    ...(comingSoonList ? [comingSoonList] : [])
  ]

  const planLabel = planLabels
    ? {
        text: planLabels.text,
        color: planLabels.colors,
        showIcon: planLabels.icon
      }
    : undefined

  const handlerClick = () => {
    gtagEvent({
      eventName: 'button_click',
      eventData: {
        cta: ctaText,
        destination_url: 'https://app.tot.money/signup',
        context: 'pricing'
      }
    })
    router.push(cardLink || 'https://app.tot.money/signup')
  }
  return (
    <PlanCard
      title={planTitle}
      description={planDescription}
      price={price}
      billingFrequencyText={billingFrequencyText}
      labelCta={ctaText}
      onCtaClick={handlerClick}
      featureSectionList={featureSections}
      billingFrequencyDiscountText={billingFrequencyDiscountText}
      label={planLabel}
      discountBadge={discountBadge}
      discountDetailsText={discountDetailsText}
      isDisabled={!isActive}
      mutateFeaturesAccordion={!isMediumScreen || !isPricingPage}
    />
  )
}

export default PricingCard
