import { mq } from '@totmoney/grid'
import styled from '@emotion/styled'
import Image from 'next/image'

import Grid from 'components/Grid'
import { H2, Paragraph } from 'components/Typography'
import PreFooterBg from 'public/illustrations/wave.png'
import Stack from 'components/Stack'
import { Button } from '@totmoney/button'
import { LinkTrackEvent } from 'helpers/events/components/LinkTrackEvent'

const PreFooterWrapper = styled(Stack)({
  gridColumn: '1 / -1',
  alignItems: 'center',
  textAlign: 'center',

  [mq.xs]: {
    gridColumn: '3 / span 8',
  },

  ['p + p']: {
    paddingBlockStart: '.5rem',
  },
})

const ImageWrapper = styled.div({
  minHeight: '6rem',
  marginBlockStart: '1.5rem',
  position: 'relative',

  ['img']: {
    position: 'absolute',
    objectFit: 'cover',
    objectPosition: 'center',
    pointerEvents: 'none',
    width: '100%',
    bottom: '-100%',

    [mq.xl]: {
      objectFit: 'unset',
    },
  },
})

const PreFooter = ({
  title,
  text,
  cta,
  url,
}: {
  title: string
  text: React.ReactNode
  cta?: string
  url?: string | (() => void)
}) => {
  return (
    <>
      <Grid>
        <PreFooterWrapper>
          <H2 as='p'>{title}</H2>
          <div>
            <Paragraph as='h2'>{text}</Paragraph>
          </div>
          {cta && url ? (
            typeof url === 'string' ? (
            <LinkTrackEvent contextText={title} ctaText={cta} type='button' href={url}>{cta}</LinkTrackEvent>
            ) : (
              <Button onClick={url}>{cta}</Button>
            )
          ) : null}
        </PreFooterWrapper>
      </Grid>
      <ImageWrapper>
        <Image src={PreFooterBg} alt='' />
      </ImageWrapper>
    </>
  )
}

export default PreFooter
