import { Button } from '@totmoney/button'
import Link from 'next/link'
import React, { PropsWithChildren } from 'react'


export type LinkButtonProps =  PropsWithChildren<{ href: string } & React.ComponentProps<typeof Button>>

export const LinkButton: React.FC<LinkButtonProps> =  ({href, children, ...props}) =>

  <Button
  as={Link}
    {...props}
    // @ts-ignore - Typing bug in @totmoney/button, Button should inherit prop types from the component passed in `as` but it doesn't
    href={href}
  >
    {children}
  </Button>
